<template>
  <div>
    <slot name="form" />

    <VueRecaptcha
      ref="recaptcha"
      :sitekey="env.CAPTCHA_KEY"
      :size="captchaVisibility"
      @verify="onRecaptchaVerify"
      @expired="onRecaptchaExpired"
    />

    <slot name="button" />
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import axios from 'axios';
import env from '../env';

export default {
  components: {
    VueRecaptcha,
  },

  emits: [
    'approve',
    'failed',
  ],

  data: () => ({
    env,
    captchaVisibility: 'invisible',
  }),

  mounted () {
    this.triggerCaptcha(); // Trigger the captcha verification on component mount
    document.addEventListener('click', this.onClickOutside);
  },

  methods: {
    async onRecaptchaVerify (response) {
      if (response) {
        try {
          await axios.get(`/api/v1/validate-recaptcha/${response}`);

          this.$emit('approve', { responseToken: response });
          this.captchaVisibility = 'invisible';
        } catch (e) {
          this.$emit('failed');
        }
      } else {
        this.$emit('failed');
      }
    },

    onClickOutside () {
      this.$refs.recaptcha?.execute();
    },

    triggerCaptcha () {
      this.$refs.recaptcha?.execute();
    },

    onRecaptchaExpired () {
      this.$refs.recaptcha?.reset();
    },
  },
};
</script>
