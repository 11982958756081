<template>
  <div class="bank-list">
    <div
      v-for="(bank, index) in banks"
      :key="index"
      class="bank-list__tile"
    >
      <LogoTile
        v-model="bank.id"
        :dusk="bank.title"
        :icon-name="bank.title"
        :name="bank.title"
        :image-title="bank.title"
        :image-url="bank.logo"
        :use-icon="false"
        :checked="bank.id === modelValue"
        @click="$emit('update:modelValue', bank.id); $emit('click', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import LogoTile from '../../components/LogoTile.vue';
import { availableBanks } from '@/js/api';

interface Bank {
  title: string
  logo: string
  id: string
}

interface Props {
  modelValue: string | null
}

withDefaults(defineProps<Props>(), {
  modelValue: '',
});

defineEmits([
  'update:modelValue',
  'click',
]);

const banks = ref<Bank[]>([]);

const response = await availableBanks.getList();
(response.data?.banks as []).forEach((bank: Bank) => banks.value.push({ title: bank.title, logo: bank.logo, id: bank.id }));
</script>

<style scoped lang="scss">
@import '@sass/tools/variables.scss';
@import '@sass/tools/mixins.scss';

.bank-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 17px;
  padding-bottom: 20px;
  justify-content: center;

  > div {
    display: flex;
    justify-content: center;
  }

  @include media(min, $xs) {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
