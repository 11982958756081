export default [
  'Bc.',
  'BcA.',
  'Mgr.',
  'MgA.',
  'Ing.',
  'Ing. arch.',
  'MUDr.',
  'MVDr.',
  'JUDr.',
  'PhDr.',
  'RNDr.',
  'PharmDr.',
  'ThDr.',
  'Ph.D.',
  'Th.D.',
  'ArtD.',
  'doc.',
  'prof.',
];
