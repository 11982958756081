<template>
  <div class="field">
    <div class="switch-group__title">
      {{ title }}

      <div
        v-for="option in normalizedOptions"
        :key="option.value"
        class="switch-group"
      >
        <LitSwitch
          class="mt-10"
          :model-value="option.checked"
          :errors="errors"
          :label="option.label"
          :dusk="option.value"
          @update:model-value="onSwitch($event, option)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import LitSwitch from './LitSwitch.vue';

interface Option {
  label: string
  value: string
  checked?: boolean
}

interface Props {
  title?: string
  name?: string
  modelValue: string | null
  options: Option[]
  errors: string[]
}

const props = defineProps<Props>();

const emit = defineEmits([
  'update:modelValue',
]);

const normalizedOptions = computed(() => {
  return props.options.map((option) => ({
    ...option,
    checked: option.value === props.modelValue,
  }));
});

function onSwitch (checked: boolean, option: Option) {
  emit('update:modelValue', checked ? option.value : null);
}
</script>
