import axios from 'axios';
import env from '../env';

export async function create (contractData: unknown, contractType = 'online', render = true, signMethod: string, verifiedBank: string | null = null) {
  const response = await axios.post(`${env.REST_API_URI}/v1/contracts/previews`, {
    contract: contractData,
    type: contractType,
    render,
    signMethod,
    verifiedBank,
  }, {
    responseType: render ? 'blob' : 'json',
  });

  return response;
}

export default {
  create,
};
