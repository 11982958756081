<template>
  <div dusk="before-start-wrapper">
    <div class="benefits bg-blue pt-30 pb-10">
      <div class="container--sm" />
    </div>

    <div class="container--sm my-30">
      <h2>Než začneme...</h2>

      <form
        dusk="before-start"
        @submit.prevent="submitStep"
      >
        <div class="mb-30">
          <DistributorData
            store-path="beforeStart"
            namespace="distribution"
            :show-meeting-address-title="beforeStart.meetingAddress?.setManually.value"
            :display-address-field="beforeStart.isTiedAgent.value"
            :show-alert="!beforeStart.isTiedAgent.value"
            :validate-fnc="validateField"
          />
        </div>
      </form>
    </div>

    <div class="container my-30">
      <hr>
      <StepButtons
        namespace="distribution"
        button-position="flex-center"
        :display-previous-step-button="false"
        @submit="submitStep"
      />
    </div>

    <DistributionNameMissingModal
      :is-distribution-name-missing="missingDistributionName"
      storage-key="distribution"
      route-name="distribution.home"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { storeToRefs } from 'pinia';
import { isBoolean } from 'lodash';
import DistributorData from '@/js/views/common/DistributorData.vue';
import StepButtons from '@/js/views/common/StepButtons.vue';

import { scrollToError, syncPersistedDistributor } from '@/js/utils';
import gtm from '@/js/services/gtm';
import { useDistributionStore, useGeneralStore } from '@/js/stores';
import { beforeStartFields } from '@/js/data/validationFields/distribution';
import DistributionNameMissingModal from '@/js/components/Modals/DistributionNameMissingModal.vue';

const store = useDistributionStore();
const { validateField } = store;
const { beforeStart } = storeToRefs(store);

const generalStore = useGeneralStore();

const route = useRoute();
const router = useRouter();

const validating = ref(false);
const missingDistributionName = ref(false);

const isDistributionNameMissing = computed(() => {
  // csob-ps was removed from bank offer, but could stay in localStorage
  return beforeStart.value.distributionName.value === ''
    || beforeStart.value.distributionName.value === null
    || beforeStart.value.distributionName.value === 'csob-ps';
});

onMounted(() => {
  const { distributionName, isTiedAgent } = beforeStart.value;

  if (distributionName.value === null) {
    beforeStart.value.distributionName.value = generalStore.distribution.value;
  }

  if (!isBoolean(isTiedAgent.value)) {
    beforeStart.value.isTiedAgent.value = generalStore.isTiedAgent.value;
  }

  syncPersistedDistributor(beforeStart.value.isTiedAgent.value, beforeStart.value.distributionName.value);

  if (isDistributionNameMissing.value) {
    missingDistributionName.value = true;
  }
});

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await store.validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: beforeStartFields,
    });

    validating.value = false;

    await router.push({
      name: 'distribution.salesInterview',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('nez-zacneme');
  } catch (e) {
    validating.value = false;

    if (isDistributionNameMissing.value) {
      missingDistributionName.value = true;
    }

    if (beforeStart.value.isTiedAgent.value && typeof beforeStart.value.meetingAddress !== 'undefined') {
      beforeStart.value.meetingAddress.setManually.value = true;
    }

    scrollToError();

    console.warn(`There was a validation contactInformationError: ${e}`);
  }
}
</script>
