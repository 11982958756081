<script setup lang="ts"  generic="T extends CurrentContractReturn">
import type { CurrentContractReturn } from '@/js/stores/types.ts';
import pensionCompanies from '@/js/data/pensionCompanies';
import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat.ts';

interface Props {
  storePath: T
  namespace: string
  isFromTransfer: boolean
}

const props = defineProps<Props>();

function pensionCompany (name: string | null) {
  const pensionCompany = pensionCompanies.find((company) => company.value === name);

  return pensionCompany?.label;
}

function formatDate (date: string | null) {
  if (date === null) {
    return '';
  }

  return useDateTimeFormat(new Date(date), 'dd. MM. yyyy');
}
</script>

<template>
  <template v-if="props.storePath.type.value === 'pensionCompany'">
    <tr>
      <td>Název penzijní společnosti</td>
      <td
        colspan="2"
      >
        {{ pensionCompany(props.storePath.companyName.value) }}
      </td>
    </tr>
    <tr>
      <td>Číslo stávající smlouvy</td>
      <td
        colspan="2"
      >
        {{ props.storePath.contractNumber.value }}
      </td>
    </tr>
    <tr v-if="!props.isFromTransfer">
      <td>Datum ukončení stávajícího penzijního spoření</td>
      <td
        colspan="2"
      >
        {{ formatDate(props.storePath.contractEndsAt.value) }}
      </td>
    </tr>
  </template>
</template>
