<script setup lang="ts" generic="T extends BeforeStartReturn">
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import type { BeforeStartReturn } from '@/js/stores/createStoreFields';

interface Props {
  storePath: T
  namespace: string
}

const props = defineProps<Props>();
</script>

<template>
  <table class="recapitulation-table mb-16">
    <thead>
      <tr>
        <th
          colspan="2"
        >
          <h3>Kontaktní údaje distributora</h3>
        </th>
        <th>
          <router-link
            :to="{ name: `${props.namespace}.beforeStart` }"
            class="edit-section"
          >
            <LitIconSvg icon-name="edit_green" />

            Upravit
          </router-link>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>Jméno</td>
        <td
          colspan="2"
        >
          {{ props.storePath.distributor.firstName.value }}
        </td>
      </tr>
      <tr>
        <td>Příjmení</td>
        <td
          colspan="2"
        >
          {{ props.storePath.distributor.lastName.value }}
        </td>
      </tr>
      <tr>
        <td>E-mail</td>
        <td
          colspan="2"
        >
          {{ props.storePath.distributor.email.value }}
        </td>
      </tr>
      <tr>
        <td>S1</td>
        <td
          colspan="2"
        >
          {{ props.storePath.s1.value }}
        </td>
      </tr>

      <tr>
        <td>S2</td>
        <td
          colspan="2"
        >
          {{ props.storePath.s2.value }}
        </td>
      </tr>
      <tr v-if="props.storePath.s3.value">
        <td>S3</td>
        <td
          colspan="2"
        >
          {{ props.storePath.s3.value }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
