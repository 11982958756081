import type {
  EntryMethodSelection,
  FinishMethodSelection,
  Nominee,
  NullableString,
  SubmitResponse,
} from '@/js/stores/types';

import { useStoreField } from '@/js/composables/useStoreField';
import {
  addressField,
  contributionAndStrategy,
  legalRequirements,
  personalData,
} from '@/js/stores/createStoreFields';

export const contactInformation = ({
  firstName = '',
  lastName = '',
  email = '',
  phoneNumber = '',
}) => ({
  firstName: useStoreField(firstName),
  lastName: useStoreField(lastName),
  email: useStoreField(email),
  phoneNumber: useStoreField(phoneNumber),
});

export const state = {
  // Is set to true when contract detail is fetched
  // and store is hydrated with the data.
  rehydrated: useStoreField(false, false),

  contactInformation: {
    ...contactInformation({}),
    contractFor: useStoreField('0'),
  },

  documents: {
    idCardFrontId: useStoreField<NullableString>(''),
    idCardBackId: useStoreField<NullableString>(''),
    secondDocumentFrontId: useStoreField<NullableString>(''),
  },

  personalData: {
    verifiedBank: useStoreField<NullableString>('', true, undefined, true),
    ...personalData({}),
    selectedEntryMethod: useStoreField<EntryMethodSelection | null>(null),

    idCard: {
      number: useStoreField<NullableString>('', true, undefined, true),
      validUntil: useStoreField<NullableString>('', true, undefined, true),
      issuedBy: useStoreField<NullableString>('', true, undefined, true),
    },

    secondDocument: {
      number: useStoreField<NullableString>(''),
      validUntil: useStoreField<NullableString>(''),
      issuedBy: useStoreField<NullableString>(''),
    },
  },

  legalRepresentative: {
    firstName: useStoreField<NullableString>(''),
    lastName: useStoreField<NullableString>(''),
    personalIdNumber: useStoreField<NullableString>(''),
    citizenship: useStoreField<NullableString>('cz'),
    countryOfBirth: useStoreField<NullableString>('cz'),
    birthplace: useStoreField<NullableString>(''),
    isPoliticallyExposed: useStoreField<boolean | null>(null),
    permanentAddress: addressField(),
  },

  // @see LegalRequirements.vue
  legalRequirements: legalRequirements(),

  contributionAndStrategy: contributionAndStrategy({}),

  contractSettings: {
    contractCreationDate: useStoreField(''),
    taxOptimization: useStoreField(false),
    contributionFromEmployer: useStoreField(false),

    employerInfo: {
      name: useStoreField<NullableString>(null),
      vat: useStoreField<NullableString>(null),
    },

    transferExistingRetirementSavings: useStoreField(false),

    currentContract: {
      type: useStoreField<NullableString>(null),
      companyName: useStoreField<NullableString>(null),
      contractNumber: useStoreField<NullableString>(null),
      contractEndsAt: useStoreField<NullableString>(null),
    },

    specifyNominees: useStoreField(false),
    nominees: [] as Nominee[],
    nomineesShare: useStoreField(50),

    usePromoCode: useStoreField(false),

    bankAccount: {
      prefix: useStoreField<NullableString>(''),
      number: useStoreField<NullableString>(''),
      bankCode: useStoreField<NullableString>(null),
      ownershipConfirmation: useStoreField(false),
    },

    // eslint-disable-next-line ts/ban-types
    finishMethod: useStoreField<FinishMethodSelection | (string & {})>(''),

    courierData: {
      fullName: useStoreField<NullableString>(''),
      companyName: useStoreField<NullableString>(''),
      contactAddress: addressField(false),
      phoneNumber: useStoreField<NullableString>(''),
    },

    promoCode: useStoreField<boolean | string>(''),
    s1: useStoreField('6999990'),
    s2: useStoreField<NullableString>(''),
  },

  consents: {
    marketingPurposes: useStoreField(false),
    sharingInformation: useStoreField(true),
    usingEmailAddress: useStoreField(true),
  },

  signature: {
    smsKey: useStoreField<NullableString>(''),
    phoneNumber: useStoreField<NullableString>(''),
  },

  // This value is received at the very and after contract is submitted
  submitResponse: useStoreField<SubmitResponse | null>(null, false),
};
