<template>
  <div dusk="thank-you">
    <div class="bg-green py-15 py-md-30 mb-30">
      <div class="container--sm">
        <LitAlert
          v-if="!isBankIdEntry"
          alert-type="success"
        >
          <h2>Děkujeme vám za sjednání penzijního spoření</h2>

          <strong>
            Gratulujeme k úspěšnému odeslání žádosti o penzijní
            spoření.
          </strong>

          <template v-if="isCourier">
            Veškeré dokumenty k vaší nové smlouvě a další pokyny jsme zaslali na vaši
            emailovou adresu.<br>

            <strong>Nyní už jen stačí odeslat</strong> níže uvedenou ověřovací
            platbu ve výši {{ verificationPayment }}&nbsp;Kč.
          </template>

          <template v-else>
            Veškeré dokumenty k vaší nové smlouvě a další pokyny zasíláme na vaši
            e-mailovou adresu, abyste si je mohli před podpisem smlouvy
            prostudovat.<br>

            <strong>Nyní už jen stačí vyčkat</strong>, až se vám ozve kurýr
            s termínem schůzky.
          </template>
        </LitAlert>

        <LitAlert
          v-else
          alert-type="success"
        >
          <h2 class="bankid_alert">
            Hotovo, máte penzijko!
          </h2>
        </LitAlert>
      </div>
    </div>

    <div class="container--sm">
      <template v-if="isCourier && !isBankIdEntry">
        <div class="box mb-20">
          <h3>Ověřovací platba</h3>

          <div class="payment-info">
            <div class="card-info">
              <div class="div-table">
                <div class="div-table__row mb-10">
                  <div class="div-table__cell--first">
                    Z vašeho účtu:
                  </div>

                  <div class="div-table__cell text-bold">
                    {{ contractSettings.bankAccount.prefix.value }}<span v-if="contractSettings.bankAccount.prefix.value">-</span>
                    {{ contractSettings.bankAccount.number.value }}/{{ contractSettings.bankAccount.bankCode.value }}
                  </div>
                </div>

                <LitAlert
                  class="mb-10"
                  alert-type="warning"
                >
                  Platbu je nutné odeslat z účtu, který jste uvedli ve smlouvě.
                </LitAlert>

                <div class="div-table__row mb-10">
                  <div class="div-table__cell--first">
                    Na účet:
                  </div>

                  <div class="div-table__cell text-bold">
                    {{ targetAccount }}
                  </div>
                </div>

                <div class="div-table__row mb-10">
                  <div class="div-table__cell--first">
                    Částka:
                  </div>

                  <div class="div-table__cell text-bold">
                    {{ verificationPayment }}&nbsp;Kč
                  </div>
                </div>

                <div class="div-table__row mb-10">
                  <div class="div-table__cell--first">
                    Variabilní symbol:
                  </div>

                  <div class="div-table__cell text-bold">
                    {{ variableSymbol }}
                  </div>
                </div>

                <div class="div-table__row mb-10">
                  <div class="div-table__cell--first">
                    Specifický symbol:
                  </div>

                  <div class="div-table__cell text-bold">
                    {{ personalIdNumber }}&nbsp;
                    <span>(vaše rodné číslo)</span>
                  </div>
                </div>

                <div class="div-table__row mb-10">
                  <div class="div-table__cell--first">
                    Konstantní symbol:
                  </div>

                  <div class="div-table__cell text-bold">
                    {{ constantSymbol }}
                  </div>
                </div>
              </div>
            </div>

            <div class="qr-code">
              <QrcodeVue
                v-if="qrCodePayment"
                :value="qrCodePayment"
                :size="240"
                level="H"
              />
            </div>
          </div>
        </div>

        <LitAlert>
          <strong>Platbu očekáváme do 1 - 2 dní.</strong> Díky ní ověříme, že
          jste to opravdu vy. Platební údaje jsme vám poslali také do e-mailu.
        </LitAlert>
      </template>
      <div v-if="!isBankIdEntry">
        <h2>Co bude nyní následovat?</h2>

        <ul
          v-if="isCourier"
          class="mb-30"
        >
          <li class="mb-5">
            <strong>Nyní čekáme na připsání vaší ověřovací platby
              {{ verificationPayment }}&nbsp;Kč</strong>,
            viz výše.
          </li>

          <li>
            Jakmile od vás ověřovací platbu obdržíme, vše zkontrolujeme a
            <strong>potvrdíme zpět e-mailem, že je vaše nové penzijní spoření
              aktivní</strong>.
          </li>
        </ul>

        <ul
          v-else
          class="mb-30"
        >
          <li class="mb-5">
            <strong>Do 2 pracovních dnů vás bude kontaktovat kurýr</strong>
            a přiveze vám smlouvu k podpisu. Smlouvu si tedy nemusíte sami
            tisknout, kurýr ji pro vás přiveze připravenou.
          </li>

          <li>
            Na schůzku s kurýrem si <strong> připravte občanský průkaz</strong>.
          </li>
        </ul>
      </div>
      <div v-else>
        <h2>Teď se koukněte do e-mailu</h2>

        <h4>Poslali jsme vám tam:</h4>

        <ul class="mb-30">
          <li class="mb-5">
            smlouvu o penzijním spoření a
          </li>

          <li>
            další dokumenty k vašemu novému penzijnímu spoření.
          </li>
        </ul>

        <LitAlert alert-type="info">
          Až bude smlouva účinná, zašleme vám do e-mailu pokyny, jak si na penzijko platit.
        </LitAlert>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

import QrcodeVue from 'qrcode.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';

import buildPaymentDescriptor from '@/js/services/buildPaymentDescriptor';
import { destroy, show } from '@/js/api/contracts';
import gtm from '@/js/services/gtm';
import { useOnlineAgreementStore } from '@/js/stores';
import { measureAdobeAnalytics, resetState } from '@/js/stores/utils';

const route = useRoute();

const store = useOnlineAgreementStore();
const {
  isBankIdEntry,
  contractSettings,
  isOnlineFinish: isCourier,
  personalData,
  submitResponse,
} = storeToRefs(store);

const loading = ref(false);
const isFromBankId = ref(false);
const contractNumber = ref(null);

const constantSymbol = ref('3558');
const targetAccount = ref('2106990187/2700');
const verificationPayment = ref(1);

const targetAccountIban = computed(() => {
  // CZ -> Country code (2 chars)
  // 65 -> check digits (2 chars)
  // bankCode (4 chars)
  // accountNumber (16 chars)

  const account = targetAccount.value.split('/');

  const code = account[1].padStart(4, '0');
  const number = account[0].padStart(16, '0');

  return `CZ65${code}${number}`;
});

const personalIdNumber = computed(() => {
  return personalData.value.personalIdNumber.value.replace('/', '');
});

const variableSymbol = computed(() => {
  if (isFromBankId.value) {
    return contractNumber.value;
  }

  return submitResponse.value.value?.contractNumber;
});

const qrCodePayment = computed(() => {
  return buildPaymentDescriptor(
    targetAccountIban.value,
    Number.parseFloat(verificationPayment.value.toString()).toFixed(2),
    'CZK',
    'Penzijní spoření',
    variableSymbol.value,
    personalIdNumber.value,
    constantSymbol.value,
  );
});

function clearLocalContractData () {
  resetState(store.$state);
}

onMounted(async () => {
  const { contractUuid } = route.params;

  gtm.onFormSubmit(variableSymbol.value);

  measureAdobeAnalytics({
    state: store.$state,
    action: 'ufFormSuccess',
    contractUuid,
    path: route.path,
  });

  measureAdobeAnalytics({
    state: store.$state,
    action: 'formStepView',
    contractUuid,
    path: route.path,
    fields: [
      { storePath: 'contractSettings.contributionFromEmployer.value', fieldName: 'employercontribution' },
      { storePath: 'contractSettings.finishMethod.value', fieldName: 'contractdeliverymethod' },
    ],
  });

  if (route.query.bankId === 'success') {
    loading.value = true;
    isFromBankId.value = true;

    const { data } = await show(contractUuid as string, 'onlineAgreement');

    contractNumber.value = data.contract_number;
    loading.value = false;

    await destroy(contractUuid as string);
  }
});

onBeforeRouteLeave((to, _from, next) => {
  clearLocalContractData();

  const disallowedPaths = [
    'onlineAgreement.contractSmsSignature',
    'onlineAgreement.contractDocumentsAndConsents',
  ];

  if (disallowedPaths.includes(to.name as string)) {
    next({ name: 'onlineAgreement.home' });
  } else {
    next();
  }
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins';
@import '@sass/tools/variables';
@import '@sass/tools/functions';

.bg-green {
  background-color: getColor(bg-green);
  overflow-y: hidden;
}

h3 {
  text-align: center;

  @include media(min, $md) {
    text-align: left;
  }
}

.short {
  width: 100%;

  @include media(min, $md) {
    width: 50%;
  }
}

.payment-info {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include media(min, $md) {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .card-info {
    flex: 2;
  }

  .qr-code {
    width: 240px;
    height: 240px;
    flex: 1;
    margin-left: 0;
    margin-top: 20px;

    @include media(min, $md) {
      margin-left: 20px;
      margin-top: 0;
    }
  }

  .div-table {
    display: flex;
    flex-direction: column;

    &__row {
      display: flex;
    }

    &__cell {
      display: flex;

      &--first {
        flex-basis: 167px;
      }
    }
  }

  span {
    font-weight: $normal;
  }
}

li {
  list-style-image: url('images/icons/dot.svg');
}

.bankid_alert {
  margin-bottom: 0;
  padding: 17px 0 10px;
}

.cta {
  background: getColor(cta);
  display: flex;
  padding: 15px;

  &-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    line-height: 1.45;
    width: 100%;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    margin-right: 15px;

    img {
      width: 25px;
    }
  }
}
</style>
