const env = window.env || {};

const {
  APP_ENV,
  APP_DEBUG,
  APP_VERSION,
  APP_URL,

  // ČSOB REST API
  REST_API_URI,

  // WebSockets
  WS_ENABLE,
  WS_SERVER_HOST,
  WS_SERVER_PORT,
  WS_APP_KEY,
  WS_APP_CLUSTER,

  // Google maps
  GOOGLE_API_KEY,

  // Smartforms,
  SMARTFORMS_CLIENT_ID,

  // GTM,
  GTM_ENABLE,

  // Adobe analytics
  AA_ENABLE,
  AA_ACCOUNT,
  AA_PROPERTY,
  AA_DEBUG,

  // Vue router
  VUE_ROUTER_MODE,

  // Sentry
  SENTRY_DSN,
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,

  // Agent info
  AGENT_DESKTOP,
  AGENT_TABLET,
  AGENT_MOBILE,

  // Feature flag
  ENABLE_LITSIGN,

  // Litea PDF Viewer
  PDF_VIEWER_URL,

  // DevTools
  DEVTOOLS_ENABLE,

  // Captcha key
  CAPTCHA_KEY,
  CAPTCHA_URI,

  // BankID
  BANKID_ENABLED,

  SHOW_REQUEST_CHANGES,
} = env;

export default {
  APP_ENV,
  APP_DEBUG,
  APP_URL,
  APP_VERSION,
  REST_API_URI,
  WS_ENABLE,
  WS_SERVER_HOST,
  WS_SERVER_PORT,
  WS_APP_KEY,
  WS_APP_CLUSTER,
  GOOGLE_API_KEY,
  SMARTFORMS_CLIENT_ID,
  GTM_ENABLE,
  AA_ENABLE,
  AA_ACCOUNT,
  AA_PROPERTY,
  AA_DEBUG,
  VUE_ROUTER_MODE,
  SENTRY_DSN,
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  AGENT_DESKTOP,
  AGENT_TABLET,
  AGENT_MOBILE,
  ENABLE_LITSIGN,
  PDF_VIEWER_URL,
  DEVTOOLS_ENABLE,
  CAPTCHA_KEY,
  CAPTCHA_URI,
  BANKID_ENABLED,
  SHOW_REQUEST_CHANGES,
};
