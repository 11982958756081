<template>
  <Menu
    v-slot="{ open }"
    class="dropdown"
    as="div"
    v-bind="attrs"
  >
    <MenuButton as="div" role="button" class="dropdown__button">
      <slot :open="open" :disabled="disabled">
        <button :disabled="disabled">
          Open
        </button>
      </slot>
    </MenuButton>

    <MenuItems
      v-if="items.length"
      class="dropdown__items"
      :class="{
        'dropdown__items--left': itemsPosition === 'left',
        'dropdown__items--right': itemsPosition === 'right'
      }"
    >
      <MenuItem
        v-for="(item, index) in items"
        :key="item.label"
        class="dropdown__item"
        as="div"
      >
        <LitLink
          v-bind="item"
          :class="{
            current: item.isActive
          }"
          :disabled="item.disabled && 'disabled'"
          @click="item.click && item.click()"
        >
          <template v-if="showIndex">
            {{ index + 1 }}.&nbsp;
          </template>{{ item.label }}
        </LitLink>
      </MenuItem>
    </MenuItems>
  </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useAttrs } from 'vue';
import type { RouterLinkProps } from 'vue-router';

import LitLink from './LitLink.vue';

defineOptions({
  inheritAttrs: false,
});

withDefaults(defineProps<Props>(), {
  disabled: false,
  items: () => [],
  showIndex: true,
  itemsPosition: 'auto',
  offset: '100%',
});

const attrs = useAttrs();

export type DropdownItem = {
  label: string
  labelClass?: string
  slot?: string
  disabled?: boolean
  isActive?: boolean
  class?: string
  click?: Function
} & RouterLinkProps;

interface Props {
  disabled?: boolean
  items: DropdownItem[]
  showIndex?: boolean
  itemsPosition?: 'left' | 'right' | 'auto'
  offset?: string
}
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

a[disabled='true'] {
  pointer-events: none;
  color: getColor('light-blue');
  cursor: default;
}

.dropdown {
  position: relative;

  &__items {
    outline: none;
    background: white;
    border-radius: 8px;
    padding: 16px 4px;
    position: absolute;
    z-index: 1000;
    top: v-bind(offset);
    box-shadow: 0 2px 8px 0 #beccd04d;
    min-width: 250px;
    max-width: 400px;

    &--left {
      right: 0;
    }

    &--right {
      left: 0;
    }

    .dropdown__item {
      > * {
        padding: 4px 16px;
        border-radius: 4px;

        &:not(a) {
          padding: 12px 16px;
          font-weight: $bold;
        }

        &:not(a):hover {
          background: getColor('light-grey');
        }

        flex: 1;
        line-height: 1.4;
        text-align: left;
      }

      :deep(a) {
        display: inline-block;
        font-size: $root-small;
        text-decoration: none;

        @include media(min, $sm) {
          font-size: $root;
        }

        &.current {
          color: getColor('default');
          cursor: default;
        }

        &:not([disabled='true']):not(:is(.current)):hover {
          text-decoration: underline;
        }
      }

      &:not(a) {
        display: flex;
        font-size: $root-small;
        text-decoration: none;
        color: getColor('default');

        @include media(min, $sm) {
          font-size: $root;
        }

        &[disabled='true'] {
          cursor: default;
        }
      }
    }
  }
}
</style>
