import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';

import { state as stateTransfer } from './state';

import {

  validateStoreField,
  validateStoreFields,
} from '@/js/stores/utils';
import gtm from '@/js/services/gtm';
import { personalIdNumber } from '@/js/utils';

export const useTransferStore = defineStore('transfer', () => {
  const state = reactive(stateTransfer);

  const isParticipant = computed(() => state.signer.type.value === 'participant');
  const isClientFromCzechia = computed(() => state.personalData.permanentAddressArea.value === 'cz');
  const participantsBirthDay = computed(() => {
    try {
      return personalIdNumber(state.personalData.personalIdNumber.value)?.birthDay;
    } catch (e) {
      return undefined;
    }
  });

  async function validateField ({
    contractUuid,
    fieldPath,
    pathToValidate,
    value,
    urlPath: _urlPath,
  }: {
    value: unknown
    pathToValidate: string | undefined
    contractUuid: string | string[]
    fieldPath: string
    urlPath: string
  }) {
    if (state.rehydrated.value === false) {
      console.warn('Skipping validation as store has not been hydrated yet');
      return;
    }
    try {
      await validateStoreField({
        contractUuid,
        fieldPath,
        // This is used for nominees array validation
        // where fieldPath is nominees.0.foo.value, but we need to trigger validation
        // for nominees.*.foo.value but reset errors for the nominees.0.foo.errors
        pathToValidate,
        throwOnErrors: true,
        value,
        state,
      });
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }

      const { errors } = e.response.data;

      if (errors) {
        const keys = Object.keys(errors);

        // We want only the first one as we validate single field
        const [firstError] = errors[keys[0]];

        gtm.onFormFieldError(fieldPath, firstError);
      }
    }
  }

  async function validateFields ({
    contractUuid,
    fields,
    throwOnErrors,
    fieldPaths,
    documentsSent = false,
  }: {
    contractUuid: string | string[]
    fields?: { path: string, value?: unknown, pathToValidate?: string }[]
    fieldPaths?: string | string[]
    throwOnErrors: boolean
    documentsSent?: boolean
  }) {
    return await validateStoreFields(state, {
      contractUuid,
      fields,
      throwOnErrors,
      fieldPaths,
      documentsSent,
    });
  }

  return {
    ...toRefs(state),
    isParticipant,
    isClientFromCzechia,
    participantsBirthDay,
    validateField,
    validateFields,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTransferStore, import.meta.hot));
}
