import type { ComputedRef } from 'vue';
import { ref, toValue, watchEffect } from 'vue';
import { isNull } from 'lodash';
import academicTitles from '@/js/data/academicTitles.ts';

export const useCheckTitleInName = (name: string | ComputedRef) => {
  const nameWarning = ref<string>('');

  const check = () => {
    if (isNull(toValue(name))) {
      nameWarning.value = '';

      return;
    }

    if (toValue(name).includes('.')) {
      nameWarning.value = 'jméno nesmí obsahovat titul';

      return;
    }

    academicTitles.forEach((title) => {
      title = title.replace('.', '');
      title = title.toLowerCase().trim();

      if (toValue(name).toLowerCase().includes(title)) {
        nameWarning.value = 'jméno nesmí obsahovat titul';
      }
    });
  };

  watchEffect(() => {
    nameWarning.value = '';
    check();
  });

  return nameWarning;
};
