import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';

import { state as changeRequestState } from './state';

import type {
  StoreValidationParams,
} from '@/js/stores/utils';

import {
  validateStoreField,
  validateStoreFields,
} from '@/js/stores/utils';

import gtm from '@/js/services/gtm';
import { personalIdNumber } from '@/js/utils';

export const useChangeRequestStore = defineStore('changeRequest', () => {
  const state = reactive(changeRequestState);

  const participantsBirthDay = computed(() => {
    try {
      return personalIdNumber(state.clientData.personalIdNumber.value)?.birthDay;
    } catch (e) {
      return undefined;
    }
  });

  async function validateField ({ contractUuid, fieldPath, pathToValidate, value, urlPath: _urlPath }: StoreValidationParams) {
    if (state.rehydrated.value === false) {
      console.warn('Skipping validation as store has not been hydrated yet');
      return;
    }

    try {
      await validateStoreField({
        contractUuid,
        fieldPath,
        // This is used for nominees array validation
        // where fieldPath is nominees.0.foo.value, but we need to trigger validation
        // for nominees.*.foo.value but reset errors for the nominees.0.foo.errors
        pathToValidate,
        throwOnErrors: true,
        value,
        state,
      });
    } catch (e: any) {
      if (!e.response) {
        throw e;
      }

      const { errors } = e.response.data;

      if (errors) {
        const keys = Object.keys(errors);

        // We want only the first one as we validate single field
        const [firstError] = errors[keys[0]];

        gtm.onFormFieldError(fieldPath, firstError);
      }
    }
  }

  async function validateFields ({ contractUuid, fields, throwOnErrors, fieldPaths, documentsSent = false }: {
    contractUuid: string | string[]
    fields?: { path: string, value?: unknown, pathToValidate?: string }[]
    fieldPaths?: string | string[]
    throwOnErrors: boolean
    documentsSent?: boolean
  }) {
    return await validateStoreFields(state, {
      contractUuid,
      fields,
      throwOnErrors,
      fieldPaths,
      documentsSent,
    });
  }

  return {
    ...toRefs(state),
    participantsBirthDay,
    validateField,
    validateFields,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChangeRequestStore, import.meta.hot));
}
