<script lang="ts" setup>
function onAfterEnter (el: Element) {
  (el as HTMLInputElement).style.height = 'auto';
}

function onEnter (el: Element) {
  const { width } = getComputedStyle(el);

  (el as HTMLInputElement).style.width = width;
  (el as HTMLInputElement).style.position = 'absolute';
  (el as HTMLInputElement).style.visibility = 'hidden';
  (el as HTMLInputElement).style.height = 'auto';
  const { height } = getComputedStyle(el);

  (el as HTMLInputElement).style.removeProperty('width');
  (el as HTMLInputElement).style.removeProperty('position');
  (el as HTMLInputElement).style.removeProperty('visibility');
  (el as HTMLInputElement).style.height = '0';
  // Force repaint to make sure the
  // animation is triggered correctly.
  // eslint-disable-next-line no-unused-expressions
  getComputedStyle(el).height;

  requestAnimationFrame(() => {
    (el as HTMLInputElement).style.height = height;
  });
}

function onLeave (el: Element) {
  const { height } = getComputedStyle(el);

  (el as HTMLInputElement).style.height = height;
  // Force repaint to make sure the
  // animation is triggered correctly.
  // eslint-disable-next-line no-unused-expressions
  getComputedStyle(el).height;
  requestAnimationFrame(() => {
    (el as HTMLInputElement).style.height = '0';
  });
}
</script>

<template>
  <Transition
    name="expand"
    appear
    @after-enter="onAfterEnter"
    @enter="onEnter"
    @leave="onLeave"
  >
    <slot />
  </Transition>
</template>

<style scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>

<style lang="scss">
.expand-enter-active,
.expand-leave-active {
  transition: height .2s ease-in-out;
  overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
  height: 0;
}
</style>
