<script setup lang="ts" generic="T extends DistributionPersonalData">
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';

import { useCitizenShipTranslation } from '@/js/composables/useCitizenShipTranslation';
import { usePermanentAddressAreaTranslation } from '@/js/composables/usePermanentAddressAreaTranslation';
import { useCountryTranslation } from '@/js/composables/useCountryTranslation';
import Document from '@/js/views/common/recapitulation/Document.vue';
import type { DistributionPersonalData } from '@/js/stores/types';

interface Props {
  storePath: T
  namespace: string
  isParticipant: boolean
}

const props = defineProps<Props>();
</script>

<template>
  <table class="recapitulation-table mb-16">
    <thead>
      <tr>
        <th
          colspan="2"
        >
          <h3>Osobní údaje klienta</h3>
        </th>
        <th>
          <router-link
            :to="{ name: `${props.namespace}.personalData` }"
            class="edit-section"
          >
            <LitIconSvg icon-name="edit_green" />

            Upravit
          </router-link>
        </th>
      </tr>
    </thead>

    <tbody>
      <template v-if="!isParticipant">
        <tr>
          <td>Jméno</td>
          <td
            colspan="2"
          >
            {{ props.storePath.firstName.value }}
          </td>
        </tr>
        <tr>
          <td>Příjmení</td>
          <td
            colspan="2"
          >
            {{ props.storePath.lastName.value }}
          </td>
        </tr>
      </template>
      <tr>
        <td>Státní občanství</td>
        <td
          colspan="2"
        >
          {{ useCitizenShipTranslation(props.storePath.citizenship.value) }}
        </td>
      </tr>
      <template v-if="props.storePath.permanentAddressArea.value === 'cz'">
        <tr>
          <td>Rodné číslo</td>
          <td
            colspan="2"
          >
            {{ props.storePath.personalIdNumber.value }}
          </td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td>Datum narození</td>
          <td
            colspan="2"
          >
            {{ props.storePath.birthDate.value }}
          </td>
        </tr>
        <tr>
          <td>Pohlaví</td>
          <td
            colspan="2"
          >
            {{ props.storePath.gender.value === 'f' ? 'žena' : 'muž' }}
          </td>
        </tr>
      </template>
      <tr
        v-if="props.storePath.permanentAddressArea.value === 'eu'
          && props.storePath.personalIdNumber.value"
      >
        <td>Číslo pojištěnce zdravotní pojištovny</td>
        <td
          colspan="2"
        >
          {{ props.storePath.personalIdNumber.value }}
        </td>
      </tr>
      <tr>
        <td>Místo narození</td>
        <td
          colspan="2"
        >
          {{ props.storePath.birthplace.value }}
        </td>
      </tr>
      <tr>
        <td>Trvalé bydliště</td>
        <td
          colspan="2"
        >
          {{ usePermanentAddressAreaTranslation(props.storePath.permanentAddressArea.value) }}
        </td>
      </tr>
      <tr>
        <td>Trvalá adresa</td>
        <td
          colspan="2"
        >
          <ul class="list-unstyled">
            <li>
              {{
                props.storePath.permanentAddress.street.value
                  ? props.storePath.permanentAddress.street.value
                  : props.storePath.permanentAddress.city.value
              }} {{ props.storePath.permanentAddress.streetNumber.value }}
            </li>
            <li>{{ props.storePath.permanentAddress.city.value }}</li>
            <li>{{ props.storePath.permanentAddress.zip.value }}</li>
            <li>{{ useCountryTranslation(props.storePath.permanentAddress.country.value) }}</li>
          </ul>
        </td>
      </tr>
      <tr v-if="props.storePath.specifyMailingAddress.value">
        <td>Korespondenční adresa</td>
        <td
          colspan="2"
        >
          <ul
            v-if="props.storePath.mailingAddress !== null"
            class="list-unstyled"
          >
            <li>
              {{
                props.storePath.mailingAddress.street.value
                  ? props.storePath.mailingAddress.street.value
                  : props.storePath.mailingAddress.city.value
              }} {{ props.storePath.mailingAddress.streetNumber.value }}
            </li>
            <li>{{ props.storePath.mailingAddress.city.value }}</li>
            <li>{{ props.storePath.mailingAddress.zip.value }}</li>
            <li>{{ useCountryTranslation(props.storePath.mailingAddress.country.value) }}</li>
          </ul>
        </td>
      </tr>
      <template v-if="isParticipant">
        <Document
          :store-path="props.storePath.document"
          :namespace="namespace"
        />
      </template>
      <template v-if="props.storePath.permanentAddressArea.value === 'eu'">
        <tr v-if="props.storePath.insuranceNumbers.medicalInsurance.value">
          <td>Zdravotní pojištění v ČR od data</td>
          <td
            colspan="2"
          >
            {{ props.storePath.insuranceNumbers.medicalInsurance.value }}
          </td>
        </tr>
        <tr v-if="props.storePath.insuranceNumbers.pensionInsurance.value">
          <td>Důchodové pojištění v ČR od data</td>
          <td
            colspan="2"
          >
            {{ props.storePath.insuranceNumbers.pensionInsurance.value }}
          </td>
        </tr>
        <tr v-if="props.storePath.insuranceNumbers.retirementPension.value">
          <td>Starobní důchod byl přiznán od data</td>
          <td
            colspan="2"
          >
            {{ props.storePath.insuranceNumbers.retirementPension.value }}
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
