<script setup lang="ts" generic="T extends LegalRequirementsReturn">
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import type { LegalRequirementsReturn } from '@/js/stores/createStoreFields';
import { useIncomeTranslation } from '@/js/composables/useIncomeTranslation.ts';

interface Props {
  storePath: T
  namespace: string
  isParticipant: boolean
  isSignerPoliticallyExposed: boolean
}

const props = defineProps<Props>();

function income (value: string) {
  return useIncomeTranslation(value);
}
</script>

<template>
  <table class="recapitulation-table mb-16">
    <thead>
      <tr>
        <th
          colspan="2"
        >
          <h3>Zákonné požadavky</h3>
        </th>
        <th>
          <router-link
            :to="{ name: `${namespace}.legalRequirements` }"
            class="edit-section"
          >
            <LitIconSvg icon-name="edit_green" />

            Upravit
          </router-link>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>Odvádí klient daně v České republice?</td>
        <td
          colspan="2"
        >
          {{ props.storePath.paysTaxesInCzechia.value === true ? 'Ano' : 'Ne' }}
        </td>
      </tr>
      <tr>
        <td>Je klient politicky exponovanou osobou?</td>
        <td
          colspan="2"
        >
          {{ props.storePath.isPoliticallyExposed.value === true ? 'Ano' : 'Ne' }}
        </td>
      </tr>
      <tr>
        <td>Z čeho pocházejí přijmy klienta?</td>
        <td
          colspan="2"
        >
          {{ income(props.storePath.sourceOfIncome.value) }}
        </td>
      </tr>
      <tr v-if="props.storePath.occupation.value">
        <td>Povolání klienta</td>
        <td
          colspan="2"
        >
          {{ props.storePath.occupation.value }}
        </td>
      </tr>
      <tr v-show="!props.isParticipant">
        <td>Je podepisující politicky exponovanou osobou?</td>
        <td
          colspan="2"
        >
          {{ isSignerPoliticallyExposed === true ? 'Ano' : 'Ne' }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
