import { h } from 'vue';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';

import * as OnlineAgreement from './views/online/newContract';
import * as Distribution from './views/distribution/newContract';
import * as Termination from './views/distribution/termination';

import online from './routes/online/newContract';
import distribution from './routes/distribution/newContract';
import transfer from './routes/distribution/transfer';
import jps from './routes/distribution/jps';
import termination from './routes/distribution/termination';
import generatedNumbers from './routes/generetedNumbers';

import ContributionForm from './../js/views/contribution-form/ContributionForm.vue';
import ThankYouPage from './../js/views/contribution-form/ThankYouPage.vue';

import Error404 from './../js/views/Error404.vue';
import Error500 from './views/Error500.vue';
import SessionExpired from './views/SessionExpired.vue';
import GeneralErrorPage from './views/ErrorPage.vue';
import ComponentShowcase from './views/ComponentShowcase.vue';

import LoginForm from './views/LoginForm.vue';

import env from './env';
import ErrorMismatch from '@/js/views/ErrorMismatch.vue';
import { changeRequest } from '@/js/routes/distribution/changeRequest.ts';

const routes = [
  {
    path: '/',
    component: {
      render: () => h('div'),
      async beforeRouteEnter (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        next: NavigationGuardNext,
      ) {
        // Redirect users who came directly to the root `/` to online agreement home page
        next({ name: 'onlineAgreement.home' });
      },
    },
  },

  // redirect from Smart (/online2) to correct url with concrete S1 as query param
  // see https://gitlab.litea.dev/csob-penze/csob-penze-cz/csob-penze-onboarding/-/issues/1058
  {
    path: '/online2',
    component: {
      render: () => h('div'),
      async beforeRouteEnter (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        next: NavigationGuardNext,
      ) {
        // Redirect users who came directly to the root `/` to online agreement home page
        next({
          name: 'onlineAgreement.home',
          query: { s1: '1001473' },
        });
      },
    },
  },

  {
    path: '/login',
    component: LoginForm,
  },
  {
    path: '/showcase',
    component: ComponentShowcase,
  },
  {
    path: '/stranka-nenalezena',
    name: 'notFound',
    component: Error404,
  },
  {
    path: '/chyba-mismatch',
    name: 'mismatchError',
    component: ErrorMismatch,
  },
  {
    path: '/chyba',
    name: 'error',
    component: GeneralErrorPage,
  },
  {
    path: '/chyba-500',
    name: 'internalError',
    component: Error500,
  },

  {
    path: '/zmenovy-formular',
    component: ContributionForm,
  },

  {
    path: '/zmenovy-formular/dekujeme',
    name: 'ContributionThankYou',
    component: ThankYouPage,
  },

  {
    path: '/smlouva-expirovala',
    name: 'sessionExpired',
    component: SessionExpired,
  },

  {
    path: '/kalkulacka-ztraty',
    name: 'termination.calculator',
    component: Termination.Calculator,
  },

  {
    path: '/online',
    name: 'onlineAgreement.home',
    component: OnlineAgreement.Home,
  },
  {
    path: '/distribuce',
    name: 'distribution.home',
    component: Distribution.Home,
  },
  {
    path: '/distribuce/:cp',
    name: 'distribution.cp',
    component: Distribution.Home,
  },
  {
    path: '/distribuce',
    name: 'distribution.guideTiles',
    component: Distribution.GuideTiles,
  },
  {
    path: '/:pathMatch(.*)*',
    component: Error404,
  },

  distribution,
  changeRequest,
  online,
  transfer,
  termination,
  generatedNumbers,
  jps,
];

const history = env.VUE_ROUTER_MODE === 'history'
  ? createWebHistory()
  : createWebHashHistory();

export const router = createRouter({
  history,
  routes,
  scrollBehavior () {
    return { top: 0, left: 0 };
  },
});
