<template>
  <div dusk="contract-settings">
    <OnlineAgreementStepper :current-step="4" />
    <div class="container--sm">
      <div dusk="contract-parameters">
        <h2>Nastavení parametrů penzijního spoření</h2>

        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          namespace="onlineAgreement"
          field-path="contractSettings.contractCreationDate"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateField"
        >
          <LitSelect
            label="Datum vzniku penzijního spoření"
            tooltip="Může být nejdříve první den následujícího měsíce od podpisu smlouvy.
              V případě předešlé smlouvy musí navazovat na ukončení/zahájení
              výplaty původní smlouvy."
            class="mb-20"
            dusk="contract-start-date"
            :model-value="value"
            :options="dateOriginPensionSavingsOptions"
            :error="errors.length > 0 ? errors[0] : ''"
            @update:model-value="input"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input }"
          namespace="onlineAgreement"
          field-path="contractSettings.taxOptimization"
          :default-value="false"
          :debounce="0"
          @validate="validateField"
        >
          <LitCheckbox
            class="mb-20"
            dusk="tax-optimization"
            tooltip-position="right"
            tooltip="Program daňové optimalizace zajistí, že finanční prostředky
            zaplacené nad sjednaný měsíční příspěvek budou použity
            na maximální možné snížení základu daně."
            :show-tooltip="true"
            :model-value="value"
            @update:model-value="input"
          >
            Chci využít Program daňové optimalizace
          </LitCheckbox>
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input }"
          namespace="onlineAgreement"
          field-path="contractSettings.contributionFromEmployer"
          :debounce="0"
          @validate="validateField"
        >
          <LitCheckbox
            class="mb-20"
            dusk="employer-contribution"
            :model-value="value"
            @update:model-value="input"
          >
            Na penzijní spoření mi bude přispívat i můj zaměstnavatel
          </LitCheckbox>
        </ServerValidatedFormField>

        <div v-if="contractSettings.contributionFromEmployer.value">
          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="onlineAgreement"
            field-path="contractSettings.employerInfo.name"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              dusk="employer-name"
              name="nameOfEmployer"
              label="Název zaměstnavatele"
              placeholder="Např. AAA Auto, a.s."
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="onlineAgreement"
            field-path="contractSettings.employerInfo.vat"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              name="employerId"
              dusk="employer-id"
              label="IČ zaměstnavatele"
              placeholder="Např. 95874375"
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            >
              <template #actions>
                <div class="actions-group">
                  <a
                    href="https://ares.gov.cz/ekonomicke-subjekty"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vyhledat IČO v obchodním rejstříku
                  </a>
                </div>
              </template>
            </LitInput>
          </ServerValidatedFormField>
        </div>

        <div
          v-if="transferFromPensionCompany || transferFromTransformFund"
          dusk="current-contract"
        >
          <h2>Předchozí smlouva</h2>
          <div v-if="transferFromPensionCompany">
            <ServerValidatedFormField
              v-slot="{ value, input, errors }"
              namespace="onlineAgreement"
              field-path="contractSettings.currentContract.companyName"
              :validate-on-input="true"
              :debounce="0"
              @validate="validateField"
            >
              <LitSelect
                label="Stávající penzijní společnost"
                dusk="current-contract-company"
                class="mb-20"
                :options="currentPensionCompanyOptions"
                :model-value="value"
                :error="errors.length > 0 ? errors[0] : ''"
                @update:model-value="input"
              />
            </ServerValidatedFormField>
          </div>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="onlineAgreement"
            field-path="contractSettings.currentContract.contractNumber"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              type="number"
              name="currentContractNumber"
              dusk="current-contract-number"
              placeholder="Např. 5875737291"
              label="Číslo mé současné smlouvy"
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors }"
            namespace="onlineAgreement"
            field-path="contractSettings.currentContract.contractEndsAt"
            :validate-on-input="true"
            :debounce="0"
            @validate="validateField"
          >
            <LitSelect
              class="mb-20"
              tooltip="Uveďte datum ukončení/poslední den výpovědní lhůty."
              dusk="current-contract-end"
              label="Datum ukončení tohoto penzijního spoření"
              :options="endDateCurrentPensionSavingsOptions"
              :model-value="value"
              :error="errors.length > 0 ? errors[0] : ''"
              @update:model-value="input"
            />
          </ServerValidatedFormField>
        </div>

        <div dusk="nominees">
          <h2>Určené osoby</h2>

          <LitAlert
            class="mb-20"
            dusk="nominees-alert"
          >
            Pokud do své smlouvy uvedete určené osoby, budou jim v případě
            vašeho úmrtí naspořené peníze vyplaceny bez dědického řízení.
            Určené osoby můžete kdykoliv změnit nebo doplnit.
          </LitAlert>

          <ServerValidatedFormField
            v-slot="{ value, input }"
            namespace="onlineAgreement"
            field-path="contractSettings.specifyNominees"
            :debounce="0"
            @validate="validateField"
          >
            <LitCheckbox
              class="mb-20"
              dusk="want-nominees"
              :model-value="value"
              @update:model-value="input"
            >
              Chci uvést určenou osobu
            </LitCheckbox>
          </ServerValidatedFormField>

          <div
            v-if="specifyNominees"
          >
            <div
              v-for="(person, index) in nominees"
              :key="index"
              class="nominee mb-30"
            >
              <div class="pl-34">
                <h3 class="nominee__title">
                  Určená osoba č. {{ index + 1 }}

                  <button
                    v-if="nominees.length > 1"
                    class="btn-simple btn-simple--red"
                    :dusk="`remove-nominee-${index}`"
                    @click="removeNominee(index)"
                  >
                    <i>
                      <img
                        :src="getIconUrl('icon-delete')"
                        alt="Ikona smazat"
                      >
                    </i>

                    <span>
                      Odstranit osobu č. {{ index + 1 }}
                    </span>
                  </button>
                </h3>

                <ServerValidatedFormField
                  v-slot="{ input, value, errors, validate }"
                  namespace="onlineAgreement"
                  :field-path="`contractSettings.nominees.${index}.firstName`"
                  :debounce="0"
                  @validate="validateField"
                >
                  <LitInput
                    class="mb-20"
                    label="Jméno"
                    placeholder="Např. Karel"
                    :name="`firstName${index}`"
                    :model-value="value"
                    :errors="errors"
                    @update:model-value="input"
                    @blur="validate(
                      $event.target.value,
                      'contractSettings.nominees.*.firstName'
                    )"
                  />
                </ServerValidatedFormField>

                <ServerValidatedFormField
                  v-slot="{ value, errors, input, validate }"
                  namespace="onlineAgreement"
                  :field-path="`contractSettings.nominees.${index}.lastName`"
                  :debounce="0"
                  @validate="validateField"
                >
                  <LitInput
                    class="mb-20"
                    label="Příjmení"
                    placeholder="Např. Novotný"
                    :name="`lastName${index}`"
                    :model-value="value"
                    :errors="errors"
                    @update:model-value="input"
                    @blur="validate(
                      $event.target.value,
                      'contractSettings.nominees.*.lastName'
                    )"
                  />
                </ServerValidatedFormField>

                <div v-if="person.usePersonalId.value === false">
                  <ServerValidatedFormField
                    v-slot="{ value, errors, input }"
                    namespace="onlineAgreement"
                    :validate-on-input="true"
                    :field-path="`contractSettings.nominees.${index}.birthdate`"
                    :debounce="0"
                    @validate="validateField"
                  >
                    <DatePicker
                      class="mb-20"
                      :name="`bday${index}`"
                      label="Datum narození"
                      :model-value="value"
                      :errors="errors"
                      @update:model-value="input"
                    >
                      <template #actions>
                        <div class="actions-group">
                          <button
                            class="btn-simple"
                            @click="updateNomineeField(index, 'usePersonalId', true)"
                          >
                            Místo data a státu narození zadat rodné číslo
                          </button>
                        </div>
                      </template>
                    </DatePicker>
                  </ServerValidatedFormField>

                  <ServerValidatedFormField
                    v-slot="{ value, errors, input }"
                    namespace="onlineAgreement"
                    :debounce="0"
                    :validate-on-input="true"
                    :field-path="`contractSettings.nominees.${index}.countryOfBirth`"
                    @validate="validateField"
                  >
                    <LitSelect
                      class="mb-20"
                      label="Stát narození"
                      :options="countryOfBirthOptions"
                      :model-value="value"
                      :error="errors.length > 0 ? errors[0] : ''"
                      @update:model-value="input"
                    />
                  </ServerValidatedFormField>
                </div>

                <ServerValidatedFormField
                  v-else
                  v-slot="{ value, errors, input, validate }"
                  namespace="onlineAgreement"
                  :field-path="`contractSettings.nominees.${index}.personalId`"
                  :debounce="0"
                  @validate="validateField"
                >
                  <LitInput
                    class="mb-20"
                    label="Rodné číslo"
                    placeholder="Např. 816008/0610"
                    :name="`personalId${index}`"
                    :model-value="value"
                    :errors="errors"
                    @update:model-value="input"
                    @blur="validate(
                      $event.target.value,
                      'contractSettings.nominees.*.personalId'
                    )"
                    @keydown.space.prevent
                  >
                    <template #actions>
                      <div class="actions-group">
                        <button
                          class="btn-simple"
                          :dusk="`nominee${index}.useBirthDate`"
                          @click="updateNomineeField(index, 'usePersonalId', false)"
                        >
                          Místo RČ zadat datum a stát narození
                        </button>
                      </div>
                    </template>
                  </LitInput>
                </ServerValidatedFormField>

                <AddressEntry
                  namespace="onlineAgreement"
                  :store-path="`contractSettings.nominees.${index}.permanentAddress`"
                  :dusk="`permanent-address-${index}`"
                  :name="`permanentAddress${index}`"
                  :same-address="true"
                  label="Trvalá adresa"
                  tooltip="Postupným psaním názvu ulice, čísla popisného
                  a PSČ si můžete ze seznamu vybrat potřebnou adresu."
                  placeholder="Např. Na příkopech 12, Praha 1"
                  :query="person.permanentAddress.query.value"
                  :editable="person.permanentAddress.editable.value"
                  :validate-fnc="validateField"
                  @update:query="updateNomineeField(
                    index,
                    'permanentAddress.query',
                    $event
                  )"
                  @update:editable="updateNomineeField(
                    index,
                    'permanentAddress.editable',
                    $event
                  )"
                  @place-detail-loaded="updateNomineesPermanentAddress(index, $event)"
                  @set-address-manually="updateNomineeField(
                    index,
                    'permanentAddress.setManually',
                    true
                  )"
                  @input="debouncedUpdateNomineeField(index, 'permanentAddress', $event)"
                >
                  <template #autocomplete-actions>
                    <button
                      class="btn-simple"
                      type="button"
                      :dusk="`same-address-as-clients-${index}`"
                      @click="useAddressFromPersonalData(index)"
                    >
                      Shodná s adresou účastníka smlouvy
                    </button>
                  </template>
                </AddressEntry>
              </div>
            </div>

            <div
              v-if="nominees.length === 1"
              class="custom-field part-full mb-30 pl-34"
            >
              <label>
                Podíl
              </label>

              <div class="control">
                Tato určená osoba <strong>dostane 100 % naspořených prostředků</strong>
              </div>
            </div>

            <button
              v-if="nominees.length < 2"
              class="btn-add mb-30"
              dusk="add-nominee"
              @click="addNominee"
            >
              <i>
                <img
                  :src="getIconUrl('btn-plus')"
                  alt="Ikona plus"
                >
              </i>

              <span>
                Přidat další určenou osobu
              </span>
            </button>

            <div
              v-if="nominees.length > 1"
              class="pl-34"
            >
              <h3>Rozdělení naspořené částky mezi určené osoby</h3>

              <div class="part-share">
                <div
                  v-for="(person, index) in nominees"
                  :key="index"
                  class="part-share__item"
                >
                  <div class="part-share__header mb-10">
                    <div class="part-share__title">
                      {{ person.firstName.value }} {{ person.lastName.value }}
                    </div>

                    <div class="part-share__subtitle">
                      (Určená osoba č. {{ index + 1 }})
                    </div>
                  </div>

                  <ServerValidatedFormField
                    v-slot="{ value, errors }"
                    namespace="onlineAgreement"
                    :field-path="`contractSettings.nominees.${index}.share`"
                    @validate="validateField"
                  >
                    <LitInput
                      class-name="control--xs"
                      type="number"
                      :min="1"
                      :max="99"
                      :show-append="true"
                      :model-value="value"
                      :errors="errors"
                      @update:model-value="calculateShare(index, $event)"
                    >
                      <template #append>
                        <span class="unit">
                          %
                        </span>
                      </template>

                      <template #number-actions>
                        <div class="number-actions">
                          <button
                            class="number-actions__btn"
                            @click="incrementShare(index)"
                          >
                            +
                          </button>

                          <button
                            class="number-actions__btn"
                            @click="decrementShare(index)"
                          >
                            -
                          </button>
                        </div>
                      </template>
                    </LitInput>
                  </ServerValidatedFormField>
                </div>

                <RangeSlider
                  v-model.number="nomineesShare"
                  class="part-share__range"
                  min="1"
                  max="99"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!isBankIdEntry"
          dusk="contract-delivery-signature"
        >
          <h2>Doručení a podpis smlouvy</h2>

          <LitTabs
            v-model="finishMethod"
            return-attribute="data-slug"
          >
            <LitTab
              v-if="!isPersonalDataFilledManually"
              dusk="online-finish"
              title="Online"
              time-indicator="5 minut"
              indicator-type="success"
              data-slug="online"
              :label="null"
              :title-info="[
                `Nutný <span class='text-bold'>účet v české bance</span> vedený na vás`,
                `Ze svého účtu <span class='text-bold'>odešlete 1 Kč ověřovací platbu</span>`
              ]"
            >
              <LitAlert
                class="mb-20"
                dusk="account-alert"
              >
                Nyní potřebujeme číslo vašeho účtu vedeného u české banky.<br>
                <span class="text-bold">Z tohoto účtu provedete ověřovací platbu
                  ve výši 1 Kč sloužící k potvrzení vaší totožnosti.</span>
              </LitAlert>

              <div
                class="custom-field custom-field--bank mb-20"
                for="prefix"
              >
                <label class="custom-field--bank__label">
                  Číslo vašeho účtu vedeného u české banky
                </label>

                <div class="bank-account">
                  <ServerValidatedFormField
                    v-slot="{ value, input, errors, validate }"
                    namespace="onlineAgreement"
                    field-path="contractSettings.bankAccount.prefix"
                    :debounce="0"
                    @validate="validateField"
                  >
                    <LitInput
                      name="prefix"
                      class="control--xs"
                      placeholder="Předčíslí"
                      :model-value="value"
                      :errors="errors"
                      @update:model-value="input"
                      @blur="validate($event.target.value)"
                    />
                  </ServerValidatedFormField>

                  <ServerValidatedFormField
                    v-slot="{ value, input, errors, validate }"
                    namespace="onlineAgreement"
                    field-path="contractSettings.bankAccount.number"
                    :debounce="0"
                    @validate="validateField"
                  >
                    <LitInput
                      name="cc-number"
                      class="control--md"
                      placeholder="Číslo účtu"
                      dusk="bank-number"
                      :model-value="value"
                      :errors="errors"
                      @update:model-value="input"
                      @blur="validate($event.target.value)"
                    />
                  </ServerValidatedFormField>

                  <span class="bank-account__slash">/</span>

                  <ServerValidatedFormField
                    v-slot="{ value, input, errors }"
                    namespace="onlineAgreement"
                    field-path="contractSettings.bankAccount.bankCode"
                    :validate-on-input="true"
                    default-value="Vyberte"
                    :debounce="0"
                    @validate="validateField"
                  >
                    <LitSelect
                      class="bank-code"
                      name="bankCode"
                      dusk="code-of-bank"
                      options-left="auto"
                      option-label-key="label"
                      option-value-key="bankCode"
                      :model-value="value"
                      :searchable="true"
                      :options-min-width="242"
                      :options="bankCodeOptions"
                      :error="errors.length > 0 ? errors[0] : ''"
                      @update:model-value="input"
                    >
                      <template #selected-option="option">
                        {{ option.value }}
                      </template>

                      <template #select-option="option">
                        <div class="bank-code__item">
                          <span>{{ option.value }}</span>
                          <span>{{ option.label }}</span>
                        </div>
                      </template>
                    </LitSelect>
                  </ServerValidatedFormField>
                </div>
              </div>

              <ServerValidatedFormField
                v-slot="{ value, input, errors }"
                namespace="onlineAgreement"
                field-path="contractSettings.bankAccount.ownershipConfirmation"
                :validate-on-input="true"
                :debounce="0"
                @validate="validateField"
              >
                <LitCheckbox
                  dusk="account-owner"
                  :model-value="value"
                  :error="errors.length > 0 ? errors[0] : ''"
                  :show-tooltip="true"
                  tooltip="Pokud nemáte účet vedený na své jméno nebo máte společný účet
                  s partnerkou/rem,kde jste disponentem, zvolte si doručení smlouvy kurýrem."
                  tooltip-position="right"
                  @update:model-value="input"
                >
                  Prohlašuji, že účet je vedený na mé jméno.
                </LitCheckbox>
              </ServerValidatedFormField>
            </LitTab>

            <LitTab
              dusk="courier-finish"
              title="Kurýr"
              time-indicator="3 prac. dny"
              indicator-type="danger"
              data-slug="courier"
              :label="null"
              :title-info="[
                'Kurýr se vám ozve a přiveze smlouvu k podpisu',
                `K ověření si připravte <span class='text-bold'>občanský průkaz</span>`
              ]"
            >
              <LitAlert
                v-if="!isChild"
                class="mb-20"
                dusk="courier-alert"
              >
                Na níže uvedenou adresu přijede kurýr, aby ověřil
                vaši totožnost a nechal vás podepsat smlouvu.
                Kurýr obvykle dorazí <span class="text-bold">do 2 pracovních dní</span>.
                <div v-if="isPersonalDataFilledManually">
                  Pokud chcete <span class="text-bold">spoření uzavřít rychleji,

                    <router-link
                      :to="{ name: 'onlineAgreement.documentsUpload' }"
                      @click="setUploadEntryMethod"
                    >
                      nahrajte
                    </router-link>

                    či

                    <router-link
                      :to="{ name: 'onlineAgreement.personalDataPhoneRedirect' }"
                      @click="setScanEntryMethod"
                    >
                      naskenujte
                    </router-link> vaše doklady</span> a smlouvu budete moci
                  <span class="text-bold">podepsat online</span>
                  pomocí ověřovací SMS a korunové platby.
                </div>
              </LitAlert>

              <LitAlert
                v-else
                class="mb-20"
                dusk="courier-alert"
              >
                Na níže uvedenou adresu přijede kurýr, aby ověřil Vaši totožnost
                a nechal Vás podepsat smlouvu.
                Kurýr obvykle dorazí <span class="text-bold">do 2 pracovních dní</span>.
              </LitAlert>

              <ServerValidatedFormField
                v-slot="{ value, input, errors, validate }"
                namespace="onlineAgreement"
                field-path="contractSettings.courierData.fullName"
                @validate="validateField"
              >
                <LitInput
                  class="mb-20"
                  name="name"
                  dusk="full-name"
                  label="Jméno a příjmení"
                  placeholder="František Vomáčka"
                  :model-value="value"
                  :errors="errors"
                  @update:model-value="input"
                  @blur="validate($event.target.value)"
                />
              </ServerValidatedFormField>

              <ServerValidatedFormField
                v-slot="{ value, input, errors }"
                namespace="onlineAgreement"
                field-path="contractSettings.courierData.companyName"
                @validate="validateField"
              >
                <LitInput
                  class="mb-20"
                  name="companyName"
                  label="Název firmy"
                  tooltip="Uveďte název firmy, případně upřesnění k adrese,
                  kam bude kurýr doručovat smlouvu k podpisu."
                  placeholder="Např. AAA Auto"
                  :model-value="value"
                  :errors="errors"
                  @update:model-value="input"
                />
              </ServerValidatedFormField>

              <AddressEntry
                v-show="!setContactAddressManually"
                v-model="courierContactAddress"
                v-model:query="courierContactAddressQuery"
                v-model:editable="courierContactAddressEditable"
                label="Kontaktní adresa pro kurýra"
                name="contactAddressForCourier"
                namespace="onlineAgreement"
                store-path="contractSettings.courierData.contactAddress"
                dusk="contact-address-for-courier"
                tooltip="Postupným psaním názvu ulice, čísla popisného
                a PSČ si můžete ze seznamu vybrat potřebnou adresu."
                class="mb-20"
                :validate-fnc="validateField"
                @place-detail-loaded="updateContactAddress"
                @set-address-manually="setContactAddressManually = true"
              />

              <ServerValidatedFormField
                v-slot="{ value, input, errors, validate }"
                namespace="onlineAgreement"
                field-path="contractSettings.courierData.phoneNumber"
                @validate="validateField"
              >
                <PhoneNumberInput
                  class="mb-20"
                  :allow-phone-change="true"
                  :disabled="true"
                  :model-value="value"
                  :error="errors.length > 0 ? errors[0] : ''"
                  :show-tooltip="false"
                  @update:model-value="input"
                  @blur="validate($event.target.value)"
                />
              </ServerValidatedFormField>
            </LitTab>
          </LitTabs>
        </div>

        <div dusk="promo-code">
          <button
            v-if="!usePromoCode"
            class="btn-simple mt-30"
            dusk="show-marketing-code"
            @click="usePromoCode = true"
          >
            Chci zadat kód marketingové akce
          </button>

          <div v-if="usePromoCode">
            <h2 class="flex content-center">
              Kód marketingové akce

              <button
                class="ml-10 btn-simple btn-simple--red"
                dusk="hide-marketing-code"
                @click="usePromoCode = false"
              >
                <i>
                  <img
                    :src="getIconUrl('icon-delete')"
                    alt="Ikona smazat"
                  >
                </i>

                Odstranit kód
              </button>
            </h2>

            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate }"
              namespace="onlineAgreement"
              field-path="contractSettings.promoCode"
              :debounce="0"
              @validate="validateField"
            >
              <LitInput
                class="mb-20"
                label="Kód akce"
                name="marketingCode"
                dusk="input-marketing-code"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
                @blur="validate($event.target.value)"
              />
            </ServerValidatedFormField>
          </div>
        </div>
        <div class="container mt-30">
          <hr>
        </div>

        <div class="container--sm flex flex-between">
          <router-link
            :to="{ name: 'onlineAgreement.contributionAndStrategy' }"
            class="btn btn-big btn-icon-back btn-outline"
            dusk="step-back"
          >
            <LitIconSvg
              class="btn__icon btn__icon--big"
              icon-name="arrow_green-left"
            />

            <span>Zpět</span>
          </router-link>

          <button
            class="btn btn-green btn-big btn-icon-right"
            dusk="submit-step"
            @click="submitStep"
          >
            <span>
              Pokračovat
            </span>

            <LitIconSvg
              class="btn__icon btn__icon--end btn__icon--end--big"
              icon-name="arrow_white-right"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { cloneDeep, set } from 'lodash';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useDebounceFn } from '@vueuse/core';

import {
  add,
  eachMonthOfInterval,
  format,
  lastDayOfMonth,
} from 'date-fns';

import LitSelect from '@/js/components/Base/LitSelect.vue';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitTabs from '@/js/components/Base/Tabs/LitTabs.vue';
import LitTab from '@/js/components/Base/Tabs/LitTab.vue';
import PhoneNumberInput from '@/js/components/PhoneNumberInput.vue';
import DatePicker from '@/js/components/DatePicker.vue';
import RangeSlider from '@/js/components/RangeSlider.vue';
import AddressEntry from '@/js/components/AddressEntry.vue';

import pensionCompanies from '@/js/data/pensionCompanies';

import gtm from '@/js/services/gtm';

import aa from '@/js/services/adobeAnalytics';
import bankCodes from '@/js/data/bankCodeOptions';
import OnlineAgreementStepper from '@/js/components/Steppers/OnlineAgreementStepper.vue';
import { countriesOfBirth } from '@/js/data/countryOfBirthOptions';
import { clamp, getIconUrl, scrollToError } from '@/js/utils';
import { signatures } from '@/js/api/index.js';

import { useStoreField } from '@/js/composables/useStoreField';
import { useOnlineAgreementStore } from '@/js/stores';
import { measureAdobeAnalytics } from '@/js/stores/utils';
import { addressField } from '@/js/stores/createStoreFields';

import type { Nominee } from '@/js/stores/types';

interface Address {
  city: string | null
  street: string | null
  streetNumber: string | null
  zip: string | null
  countryCode: string | null
}

function createNominee (): Nominee {
  return {
    firstName: useStoreField(''),
    lastName: useStoreField(''),
    personalId: useStoreField(''),
    usePersonalId: useStoreField(true),
    countryOfBirth: useStoreField('cz'),
    birthdate: useStoreField(''),
    share: useStoreField(100),
    permanentAddress: addressField(),
  };
}

function getNomineeFields (index: number) {
  return [
    {
      path: `contractSettings.nominees.${index}.firstName`,
      pathToValidate: 'contractSettings.nominees.*.firstName',
    },
    {
      path: `contractSettings.nominees.${index}.lastName`,
      pathToValidate: 'contractSettings.nominees.*.lastName',
    },
    {
      path: `contractSettings.nominees.${index}.personalId`,
      pathToValidate: 'contractSettings.nominees.*.personalId',
    },
    {
      path: `contractSettings.nominees.${index}.usePersonalId`,
      pathToValidate: 'contractSettings.nominees.*.usePersonalId',
    },
    {
      path: `contractSettings.nominees.${index}.countryOfBirth`,
      pathToValidate: 'contractSettings.nominees.*.countryOfBirth',
    },
    {
      path: `contractSettings.nominees.${index}.birthdate`,
      pathToValidate: 'contractSettings.nominees.*.birthdate',
    },
    {
      path: `contractSettings.nominees.${index}.share`,
      pathToValidate: 'contractSettings.nominees.*.share',
    },
    {
      path: `contractSettings.nominees.${index}.permanentAddress.street`,
      pathToValidate: 'contractSettings.nominees.*.permanentAddress.street',
    },
    {
      path: `contractSettings.nominees.${index}.permanentAddress.streetNumber`,
      pathToValidate: 'contractSettings.nominees.*.permanentAddress.streetNumber',
    },
    {
      path: `contractSettings.nominees.${index}.permanentAddress.zip`,
      pathToValidate: 'contractSettings.nominees.*.permanentAddress.zip',
    },
    {
      path: `contractSettings.nominees.${index}.permanentAddress.city`,
      pathToValidate: 'contractSettings.nominees.*.permanentAddress.city',
    },
    {
      path: `contractSettings.nominees.${index}.permanentAddress.country`,
      pathToValidate: 'contractSettings.nominees.*.permanentAddress.country',
    },
  ];
}

const MAX_NOMINEE_SHARE = 100;

const store = useOnlineAgreementStore();
const { validateField, validateFields } = store;
const {
  contractSettings,
  isBankIdEntry,
  isPersonalDataFilledManually,
  isChild,
  personalData,
  contactInformation,
  legalRepresentative,
} = storeToRefs(store);

const route = useRoute();
const router = useRouter();

const validating = ref(false);

const courierContactAddress = ref(null);
const courierContactAddressDetail = ref(null);
const courierContactAddressEditable = ref(false);
const setContactAddressManually = ref(false);
const dateFormat = ref('dd. MM. yyyy');

const countryOfBirthOptions = ref(countriesOfBirth);
const bankCodeOptions = ref(bankCodes);
const currentPensionCompanyOptions = ref(pensionCompanies);

const specifyNominees = computed(() => {
  return contractSettings.value.specifyNominees.value;
});

const dateOriginPensionSavingsOptions = computed(() => {
  const today = new Date();

  const rangeOfMonths = eachMonthOfInterval({
    start: add(today, {
      months: 1,
    }),
    end: add(today, {
      months: 6,
    }),
  });

  return rangeOfMonths.map((date) => ({
    value: format(date, 'yyyy-MM-dd'),
    label: format(date, dateFormat.value),
  }));
});

const endDateCurrentPensionSavingsOptions = computed(() => {
  const today = new Date();

  const rangeOfMonths = eachMonthOfInterval({
    start: today,
    end: add(today, {
      months: 5,
    }),
  });

  return rangeOfMonths.map((date) => ({
    value: format(lastDayOfMonth(date), 'yyyy-MM-dd'),
    label: format(lastDayOfMonth(date), dateFormat.value),
  }));
});

const nomineesShare = computed({
  get () {
    return contractSettings.value.nomineesShare.value;
  },
  set (value) {
    contractSettings.value.nomineesShare.value = value;
  },
});

const nominees = computed({
  get () {
    return contractSettings.value.nominees;
  },
  set (value) {
    contractSettings.value.nominees = value;
  },
});

const fields = computed(() => {
  const result = [
    { path: 'contractSettings.contractCreationDate' },
    { path: 'contractSettings.taxOptimization' },
    { path: 'contractSettings.contributionFromEmployer' },
    { path: 'contractSettings.employerInfo.name' },
    { path: 'contractSettings.employerInfo.vat' },
    { path: 'contractSettings.transferExistingRetirementSavings' },
    { path: 'contractSettings.currentContract.companyName' },
    { path: 'contractSettings.currentContract.contractNumber' },
    { path: 'contractSettings.currentContract.contractEndsAt' },
    { path: 'contractSettings.currentContract.type' },

    { path: 'contractSettings.bankAccount.prefix' },
    { path: 'contractSettings.bankAccount.number' },
    { path: 'contractSettings.bankAccount.bankCode' },
    { path: 'contractSettings.bankAccount.ownershipConfirmation' },

    { path: 'contractSettings.courierData.fullName' },
    { path: 'contractSettings.courierData.companyName' },
    { path: 'contractSettings.courierData.contactAddress.street' },
    { path: 'contractSettings.courierData.contactAddress.streetNumber' },
    { path: 'contractSettings.courierData.contactAddress.city' },
    { path: 'contractSettings.courierData.contactAddress.zip' },
    { path: 'contractSettings.courierData.contactAddress.country' },
    { path: 'contractSettings.courierData.phoneNumber' },

    { path: 'contractSettings.promoCode' },
  ];

  if (specifyNominees.value) {
    nominees.value.forEach((_nominee, index) => {
      result.push(...getNomineeFields(index));
    });
  }

  return result;
});

const finishMethod = computed({
  get () {
    return contractSettings.value.finishMethod.value;
  },
  set (value) {
    contractSettings.value.finishMethod.value = value;
  },
});

const transferFromPensionCompany = computed(() => {
  return contractSettings.value.currentContract.type.value === 'pensionCompany';
});

const transferFromTransformFund = computed (() => {
  return contractSettings.value.currentContract.type.value === 'transformFund';
});

const courierContactAddressQuery = computed({
  get () {
    return contractSettings.value.courierData.contactAddress.query.value;
  },
  set (value) {
    contractSettings.value.courierData.contactAddress.query.value = value;
  },
});

const usePromoCode = computed({
  get () {
    return contractSettings.value.usePromoCode.value;
  },
  set (value) {
    contractSettings.value.usePromoCode.value = value;
  },
});

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    // in case of termination/transfer with new contract it sometimes happens
    // that the share is not set acc. to the default value and null is set instead
    // therefore we make this check and setting to avoid errors until the real reason
    // of this behaviour is found
    if (nominees.value.length === 1 && nominees.value[0].share.value !== 100) {
      setShare(0, 100);
    }

    let signMethod;

    switch (true) {
      case isBankIdEntry.value:
        signMethod = 'bankId';
        break;
      case finishMethod.value === 'courier':
        signMethod = 'offline';
        break;
      case finishMethod.value === 'online':
        signMethod = 'sms';
        break;
    }

    const signature = [
      {
        signer: 'client',
        signMethod,
      },
    ];

    await signatures.update(contractUuid, JSON.stringify(signature), false, true);

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fields: fields.value,
    });

    validating.value = false;

    gtm.onStepSubmit('smlouva-nastaveni-parameteru');

    measureAdobeAnalytics({
      state: store.$state,
      action: 'ufSubmit',
      contractUuid,
      path: route.path,
      fields: [
        { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
        { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
        { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
        { storePath: 'contactInformation.email.value', fieldName: 'email' },
        { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
        { fetcher: () => true, fieldName: 'marketingagreement' },
      ],
    });

    await router.push({
      name: 'onlineAgreement.contractDocumentsAndConsents',
      params: {
        contractUuid,
      },
    });
  } catch (e) {
    validating.value = false;

    nominees.value.forEach((_nominee, index) => {
      updateNomineeField(index, 'permanentAddress.setManually', true);
    });

    scrollToError();

    console.error(`There was a validation ContractDocumentsAndConsentsError: ${e}`);
  }
}

function setScanEntryMethod () {
  personalData.value.selectedEntryMethod.value = 'scan';
  finishMethod.value = 'online';
}

function setUploadEntryMethod () {
  personalData.value.selectedEntryMethod.value = 'upload';
  finishMethod.value = 'online';
}

function addNominee () {
  if ([...nominees.value].length >= 2) {
    return;
  }

  nominees.value = [
    ...nominees.value,
    createNominee(),
  ];

  nomineesShare.value = MAX_NOMINEE_SHARE / nominees.value.length;
}

function updateNomineeField (index: number, fieldPath: string, value: unknown) {
  if (index >= nominees.value.length) {
    return;
  }

  const updatedNominee = cloneDeep(nominees.value[index]);

  set(updatedNominee, `${fieldPath}.value`, value);

  nominees.value = [
    ...nominees.value.slice(0, index),
    updatedNominee,
    ...nominees.value.slice(index + 1),
  ];
}

function removeNominee (index: number) {
  nominees.value = [
    ...nominees.value.slice(0, index),
    ...nominees.value.slice(index + 1),
  ];

  nomineesShare.value = MAX_NOMINEE_SHARE / nominees.value.length;
}

function updateNomineesPermanentAddress (nomineeIndex: number, placeDetail: Address) {
  updateAddress(
    `contractSettings.nominees.${nomineeIndex}`,
    'permanentAddress',
    placeDetail,
  );
}

function setShare (nomineeIndex: number, newShare: number) {
  updateNomineeField(nomineeIndex, 'share', newShare);
}

function calculateShare (nomineeIndex: number, share: number) {
  const clampedShare = clamp(0, MAX_NOMINEE_SHARE, share);

  if (nomineeIndex === 0) {
    nomineesShare.value = Number.parseFloat(clampedShare.toString());
  } else if (nomineeIndex === 1) {
    nomineesShare.value = MAX_NOMINEE_SHARE - Number.parseFloat(clampedShare.toString());
  } else {
    throw new Error('There should be only two nominees');
  }
}

function incrementShare (index: number, val = 1) {
  calculateShare(index, ([...nominees.value][index].share.value || 0) + val);
}

function decrementShare (index: number, val = 1) {
  calculateShare(index, ([...nominees.value][index].share.value || 0) - val);
}

function copyContactInformation () {
  if (isChild.value) {
    copyAddress('legalRepresentative', 'permanentAddress');
    copyName('legalRepresentative');

    if (!legalRepresentative.value.permanentAddress.setManually.value === true) {
      copyAddressQuery(legalRepresentative.value.permanentAddress.query.value);
    } else {
      setAddressQueryFromManuallyAddress(
        'legalRepresentative',
        'permanentAddress',
      );
    }
  } else if (personalData.value.specifyMailingAddress.value === true) {
    if (!personalData.value.mailingAddress.setManually.value === true) {
      copyAddressQuery(personalData.value.mailingAddress.query.value);
    } else {
      setAddressQueryFromManuallyAddress('personalData', 'mailingAddress');
    }

    copyName('personalData');
    copyAddress('personalData', 'mailingAddress');
  } else {
    if (!personalData.value.permanentAddress.setManually.value === true) {
      copyAddressQuery(personalData.value.permanentAddress.query.value);
    } else {
      setAddressQueryFromManuallyAddress('personalData', 'permanentAddress');
    }

    copyName('personalData');
    copyAddress('personalData', 'permanentAddress');
  }

  copyPhoneNumber();
}

function updateAddress (
  prefix: string,
  name: 'permanentAddress' | 'mailingAddress' | 'contactAddress',
  {
    city,
    street,
    streetNumber,
    zip,
    countryCode,
  }: Address,
) {
  store.$patch((state) => {
    // @ts-expect-error: TODO: improve this
    state[prefix][name].street.value = street;
    // @ts-expect-error: TODO: improve this
    state[prefix][name].streetNumber.value = streetNumber;
    // @ts-expect-error: TODO: improve this
    state[prefix][name].city.value = city;
    // @ts-expect-error: TODO: improve this
    state[prefix][name].zip.value = zip;
    // @ts-expect-error: TODO: improve this
    state[prefix][name].country.value = countryCode?.toLowerCase();
  });
}

function updateContactAddress (addressDetail: Address) {
  updateAddress('contractSettings.courierData', 'contactAddress', addressDetail);
}

function copyName (prefix: 'legalRepresentative' | 'personalData') {
  const field = store[prefix];
  contractSettings.value.courierData.fullName.value = `${field.firstName.value} ${field.lastName.value}`;
}

function copyPhoneNumber () {
  contractSettings.value.courierData.phoneNumber.value = contactInformation.value.phoneNumber.value;
}

function copyAddressQuery (queryValue: string | null) {
  courierContactAddressQuery.value = queryValue;
}

function setAddressQueryFromManuallyAddress<Prefix extends 'personalData' | 'legalRepresentative'> (
  prefix: Prefix,
  address: Prefix extends 'personalData' ? 'permanentAddress' | 'mailingAddress' : 'permanentAddress',
) {
  // @ts-expect-error: TODO: improve this
  const countryCode = store[prefix][address].country.value as string;

  const countryObject = countryOfBirthOptions.value.find(
    (country) => country.value === countryCode,
  );
  // @ts-expect-error: TODO: improve this
  courierContactAddressQuery.value = `${store[prefix][address].street.value} ${store[prefix][address].streetNumber.value}, ${store[prefix][address].city.value}, ${store[prefix][address].zip.value}, ${countryObject?.label}`;
}

function copyAddress<T extends 'personalData' | 'legalRepresentative'> (
  prefix: T,
  name: T extends 'personalData' ? 'permanentAddress' | 'mailingAddress' : 'permanentAddress',
) {
  store.$patch((state) => {
    // @ts-expect-error: TODO: improve this
    state.contractSettings.courierData.contactAddress.street.value = store[prefix][name].street.value;
    // @ts-expect-error: TODO: improve this
    state.contractSettings.courierData.contactAddress.streetNumber.value = store[prefix][name].streetNumber.value;
    // @ts-expect-error: TODO: improve this
    state.contractSettings.courierData.contactAddress.city.value = store[prefix][name].city.value;
    // @ts-expect-error: TODO: improve this
    state.contractSettings.courierData.contactAddress.zip.value = store[prefix][name].zip.value;
    // @ts-expect-error: TODO: improve this
    state.contractSettings.courierData.contactAddress.country.value = store[prefix][name].country.value;
  });

  // @ts-expect-error: TODO: improve this
  courierContactAddressDetail.value = store[prefix][name];
}

function useAddressFromPersonalData (index: number) {
  store.$patch((state) => {
    state.contractSettings.nominees[index].permanentAddress.city.value = personalData.value.permanentAddress.city.value;
    state.contractSettings.nominees[index].permanentAddress.street.value = personalData.value.permanentAddress.street.value;
    state.contractSettings.nominees[index].permanentAddress.streetNumber.value = personalData.value.permanentAddress.streetNumber.value;
    state.contractSettings.nominees[index].permanentAddress.zip.value = personalData.value.permanentAddress.zip.value;
    state.contractSettings.nominees[index].permanentAddress.country.value = personalData.value.permanentAddress.country.value;
    state.contractSettings.nominees[index].permanentAddress.query.value = personalData.value.permanentAddress.query.value;
    state.contractSettings.nominees[index].permanentAddress.setManually.value = personalData.value.permanentAddress.setManually.value;
    state.contractSettings.nominees[index].permanentAddress.editable.value = false;
  });
}

const debouncedUpdateNomineeField = useDebounceFn((index: number, fieldPath: string, value: unknown) => {
  updateNomineeField(index, fieldPath, value);
}, 300);

watch(specifyNominees, (newValue) => {
  if (newValue && nominees.value.length === 0) {
    addNominee();
  } else if (!newValue) {
    nominees.value = [];
  }
});

watch(nomineesShare, (newValue) => {
  setShare(0, newValue);
  setShare(1, MAX_NOMINEE_SHARE - newValue);
});

watch(finishMethod, async (newValue) => {
  const { contractUuid } = route.params;

  await validateFields({
    contractUuid,
    throwOnErrors: true,
    fields: [{
      value: newValue,
      path: 'contractSettings.finishMethod',
    }],
  });
});

onMounted(() => {
  // When user comes from previous page
  // i.e was not redirected fro the `/` route
  // copy contact information immediately
  if (courierContactAddressQuery.value === null) {
    copyContactInformation();
  }

  const { contractUuid } = route.params;

  measureAdobeAnalytics({
    state: store.$state,
    action: 'formStepView',
    contractUuid,
    path: route.path,
    fields: [
      {
        fieldName: 'selectionmethod',
        fetcher: (state) => (state.contributionAndStrategy.selectCustomStrategy.value === false
          ? 'recommend'
          : 'custom'
        ),
      },
      {
        fieldName: 'strategyvariant',
        fetcher:
            (state) => aa.getStrategyVariant(state.contributionAndStrategy.selectedStrategy.value),
      },
      {
        storePath: 'contributionAndStrategy.contributionAmount.value',
        fieldName: 'monthlyinvestment',
      },
    ],
  });

  if (isBankIdEntry.value) {
    finishMethod.value = 'online';
  }

  if (!isBankIdEntry.value && isChild.value) {
    finishMethod.value = 'courier';
  }
});
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.part-share {
  position: relative;

  @include media(min, $sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    flex: 1;

    &:nth-child(2) {
      @include media(min, $sm) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    /* stylelint-disable */
    &:nth-child(2)  :deep(.field) {
      @include media(min, $sm) {
        justify-content: flex-end;

        .control__wrapper {
          flex-basis: auto;
        }
      }
    }

    /* stylelint-enable */

    @include media(max, $sm) {
      margin-bottom: 20px;
    }
  }

  &__range {
    display: none;

    @include media(min, $sm) {
      display: inherit;
      position: absolute;
      left: 101px;
      right: 101px;
      bottom: (math.div($input-height, 2));
      width: auto;
    }
  }

  &__title,
  &__subtitle {
    font-weight: $bold;
    line-height: 1.45;
  }

  &__title {
    font-size: $root;
  }

  &__header {
    min-height: 43px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
  }
}

.nominee {
  &__title {
    @include media(min, $sm) {
      display: flex;
      align-items: baseline;

      button {
        margin-left: 12px;
      }
    }

    @include media(max, $sm) {
      button {
        margin-top: 10px;
      }
    }
  }
}

.actions-group {
  pointer-events: all;
}

.number-actions {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;

  .number-actions__btn {
    height: math.div($input-height, 2);
    width: math.div($input-height, 2);
    color: getColor(light-blue);
    outline: none;
    font-size: $root;

    &:hover {
      background: getColor(light-blue);
      color: getColor(white);
    }

    &:focus {
      box-shadow: 0 0 0 .05em #fff, 0 0 .15em .1em getColor(light-blue);
    }
  }
}

.control.control--unit {
  input[type=number] {
    padding-right: 50px;
    font-weight: $bold;
  }

  .unit {
    right: math.div($input-height, 2);
    width: 25px;
    font-weight: $bold;
    color: inherit;
  }
}

.custom-field {
  &.part-full {
    margin-top: -10px;
  }

  label {
    margin-bottom: 10px;
    display: block;
  }

  @include media(min, $md) {
    display: flex;
    align-items: center;

    label {
      flex-basis: 177px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  &--bank {
    &__label {
      @include media(min, $md) {
        align-self: flex-start;
        margin-top: 8px;
      }
    }

    :deep(.field-info) {
      margin-left: 0;
    }
  }
}

.bank-account {
  display: flex;

  &__slash {
    margin-top: (math.div($input-height, 2)) - 7px;
  }

  > *:not(:last-child) {
    margin-right: 5px;
  }

  .control {
    &--xs {
      flex-basis: 76px;
      max-width: 76px;
    }

    &--sm {
      flex-basis: 108px;
      max-width: 108px;
    }

    &--md {
      flex-basis: 155px;
      max-width: 155px;
    }
  }
}

.bank-code {
  width: 100px;

  &__item {
    display: flex;

    & > span:first-child {
      min-width: 50px;
    }
  }
}
</style>
