import { get, set } from 'lodash';
import type { StateTree } from 'pinia';
import { captureException, setContext } from '@sentry/vue';

import { show, update } from '../api/contracts';
import { removeContractMetadata, traverseObject } from '../utils';
import { useStoreField } from '@/js/composables/useStoreField';

import aa from '@/js/services/adobeAnalytics';

export function setStoreFields (state: StateTree, fields: { path: string, value: unknown }[]) {
  fields.forEach(({ path, value }) => {
    if (!path) {
      console.warn('Undefined field path');
    }

    if (get(state, path, undefined) === undefined) {
      console.warn(`Trying to set field path ${path} that does not exist in the store state`);
      return;
    }

    set(state, path, value);
  });
}

export function setStoreField<T> (state: StateTree, { fieldPath, value }: { fieldPath: string, value: T }) {
  setStoreFields(state, [{
    value,
    path: fieldPath,
  }]);
}

export const copyFieldsToState = (state: Record<string, unknown>, fields: any) => {
  if (fields.rootState) {
    state = fields.rootState;
    fields = fields.data;
  }

  // @ts-expect-error: TODO: fix this
  fields.forEach(({ pathFrom, pathTo, defaultValue }) => {
    if (get(state, pathTo, undefined) === undefined) {
      console.warn(`Trying to set undefined store state path ${pathTo}`);
    }

    set(
      state,
      pathTo,
      get(state, pathFrom, defaultValue),
    );
  });
};

export const prepareState = (state: unknown) => {
  const cleaned = JSON.parse(JSON.stringify(state));
  const result = {};

  traverseObject(cleaned, ({
    nodeKey,
    nodeValue,
    parent,
    parentPath,
    path,
  }) => {
    if (Array.isArray(nodeValue) && nodeValue.length === 0 && nodeKey !== 'errors') {
      // We want to keep empty arrays in the request (except errors field)
      // E.g. nominees
      set(result, path, []);
    }

    if (
      nodeKey !== '__storeField'
      || (parent && 'includeInRequest' in parent && parent.includeInRequest === false)
    ) {
      return;
    }

    // @ts-expect-error: TODO: fix this
    let { value } = parent;

    const transformation = get(
      state,
      `${parentPath}.transformation`,
      undefined,
    ) as unknown as CallableFunction; // Cast transformation to Function type

    if (typeof transformation === 'function' && parent) {
      const transformed = transformation(parent.value);

      if (transformed !== undefined) {
        value = transformed;
      }
    }

    set(result, `${parentPath}.value`, value);

    if (`${parentPath}.readOnly`) {
      // @ts-expect-error: TODO: fix this
      delete result[`${parentPath}.readOnly`];
    }

    // Store field DTO in BE (REST Client) expects field `errors`
    set(result, `${parentPath}.errors`, []);
  });

  return result;
};

export const validateStoreFields = async (state: StateTree, {
  contractUuid,
  fields,
  fieldPaths,
  throwOnErrors,
  documentsSent = false,
}: any) => {
  if (fieldPaths && !fields) {
    fields = fieldPaths.map((fieldPath: string) => ({
      path: fieldPath,
    }));
  }

  const formData = prepareState(state);

  fields.forEach((field: Record<string, unknown>) => {
    if (!field.path) {
      console.error('Field path should not be empty', field);
    }

    setStoreField(state, {
      fieldPath: `${field.path}.errors`,
      value: [],
    });
  });

  try {
    const { data } = await update(contractUuid, {
      formData,
      validateOnly: fields.map((field: Record<string, unknown>) => field.pathToValidate || field.path),
      documentsSent,
    });

    return data.contract;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }

    const { errors } = error.response.data;

    if (errors) {
      Object.entries(errors).forEach(([key, value]) => {
        // Turns formData.contactInformation.firstName.value
        // into contactInformation.firstName.errors
        const path = key
          .replace('formData.', '')
          .replace('.value', '.errors');

        setStoreField(state, {
          fieldPath: path,
          value,
        });
      });
    }

    if (throwOnErrors) {
      throw error;
    }
  }

  return null;
};

export interface StoreValidationParams {
  value: unknown
  pathToValidate: string | undefined
  fieldPath: string
  contractUuid: string | string[]
  urlPath: string
};

export type ValidateStoreParamsFnc = (params: StoreValidationParams) => Promise<void>;

/**
 *
 * @param state
 * @param value
 * @param fieldPath
 * @param contractUuid
 * @param throwOnErrors
 * @param pathToValidate
 * @returns {*} Returns null if there are no errors
 */
export const validateStoreField = <T>({
  value,
  fieldPath,
  contractUuid,
  throwOnErrors,
  pathToValidate,
  state,
}: {
  state: StateTree
  value: T
  fieldPath: string
  contractUuid: string | string[]
  throwOnErrors: boolean
  pathToValidate?: string
}) => validateStoreFields(state, {
    contractUuid,
    throwOnErrors,
    fields: [
      {
        path: fieldPath,
        value,
        // This is used for nominees array validation
        // where fieldPath is nominees.0.foo.value, but we need to trigger validation
        // for nominees.*.foo.value but reset errors for the nominees.0.foo.errors
        pathToValidate,
      },
    ],
  });

export const measureAdobeAnalytics = ({
  state,
  action,
  contractUuid,
  path,
  fields,
}: {
  state?: StateTree
  action: string
  contractUuid: string | string[]
  path: string
  fields?: {
    storePath?: string
    fieldName: string
    defaultValue?: unknown
    fetcher?: (state: StateTree) => unknown
  }[]
}) => {
  const formId = 'penzijni-sporeni';
  const formStep = aa.getFormStep(path);

  const payload: Record<string, unknown> = {};
  const items = fields || [];

  items.forEach(({
    fetcher,
    storePath,
    fieldName,
    defaultValue,
  }) => {
    let value = get(
      state,
      storePath || '',
      defaultValue || null,
    );

    if (typeof fetcher === 'function') {
      if (!state) {
        throw new Error('State is not defined');
      }

      value = fetcher(state);
    }

    payload[fieldName] = value;
  });

  try {
    aa.measure(
      action,
      formId,
      contractUuid,
      formStep,
      payload,
    );
  } catch (e) {
    console.warn(e);
  }
};

/**
 * This mutation takes the persisted state from BE
 * and re-hydrates the store state with the data.
 * @see resources/js/views/online-agreement/Layout.vue:72
 * @param state
 * @param data
 */
export const restoreState = (state: StateTree, data: Record<string, unknown>) => {
  traverseObject(state, ({
    nodeKey,
    parentPath,
    nodeValue,
    path,
  }) => {
    if (nodeKey === '__storeField') {
      const val = get(data, `${parentPath}.value`, undefined);

      if (val !== undefined) {
        set(state, `${parentPath}.value`, val);
      }
    } else if (Array.isArray(nodeValue)) {
      const val = get(data, path, []);
      const transformedArray: unknown[] = [];

      // If the node is an array we need to iterate over source data which have been
      // striped from __storeField and other metadata. Therefor we can not simply replace
      // the state value with the one being restored from DB.
      traverseObject(val as Record<string, unknown>, ({ nodeKey: nKey, nodeValue: nValue, parentPath: pPath }) => {
        if (nKey === 'value') {
          const field = useStoreField('');
          field.value = nValue as string;

          set(transformedArray, pPath as string, field);
        }
      });

      set(state, path, transformedArray);
    }
  });

  state.rehydrated.value = true;
};

/* interface FieldData {
  pathFrom: string
  pathTo: string
  defaultValue: unknown | undefined
  rootState?: unknown | null
  data?: FieldData[]
} */

export const copyFields = (state: StateTree, fields: any) => {
  if (fields.rootState) {
    state = fields.rootState;
    fields = fields.data;
  }

  fields.forEach((
    { pathFrom, pathTo, defaultValue }: {
      pathFrom: string
      pathTo: string
      defaultValue: unknown | undefined
    }) => {
    if (get(state, pathTo, undefined) === undefined) {
      console.warn(`Trying to set undefined store state path ${pathTo}`);
    }

    set(
      state,
      pathTo,
      get(state, pathFrom, defaultValue),
    );
  });
};

interface Field { pathFrom: string, pathTo: string, defaultValue?: unknown };

/**
 * Copies fields from one state tree to another.
 *
 * @param {StateTree} stateFrom - The state tree to copy from.
 * @param {StateTree} stateTo - The state tree to copy to.
 * @param {Field[]} fields - An array of field objects, each containing a pathFrom, pathTo, and an optional defaultValue.
 */
export const copyRootFields = (stateFrom: StateTree, stateTo: StateTree, fields: Field[]) => {
  for (let i = 0; i < fields.length; i++) {
    const { pathFrom, pathTo, defaultValue } = fields[i];

    try {
      let value = get(stateFrom, pathFrom);

      if (value === undefined) {
        value = defaultValue;
      }

      set(stateTo, pathTo, value);
    } catch (error) {
      console.error(`Failed to copy field from ${pathFrom} to ${pathTo}:`, error);
    }
  }
};

export const updateContract = ({ state, contractUuid, fields, documentsSent = false }: {
  state: StateTree
  contractUuid: string
  fields?: Record<string, string>[]
  documentsSent?: boolean
}) => {
  (fields || []).forEach(({ path, value }) => {
    setStoreField(state, {
      fieldPath: path,
      value,
    });
  });

  return update(contractUuid, {
    validateOnly: [],
    formData: prepareState(state),
    documentsSent,
  });
};

export const submitContract = async (state: StateTree, {
  contractUuid,
  signingKey,
  throwOnErrors,
  signature,
  removeContract = true,
}: {
  contractUuid: string
  signingKey?: string | null
  throwOnErrors: boolean
  signature: { signer: string, signMethod: string }[]
  removeContract?: boolean
}) => {
  const formData = prepareState(state);

  try {
    const { data } = await update(contractUuid, {
      formData,
      signingKey,
      validateOnly: null,
      submitContract: true,
      signature,
    });

    setStoreField(state, {
      fieldPath: 'submitResponse.value',
      value: {
        contractNumber: data.contract_number,
        metadata: data.metadata,
      },
    });

    if (data.uuid && removeContract) {
      removeContractMetadata(data.uuid);
    }

    return data.contract;
  } catch (error: any) {
    setContext('contract', {
      uuid: contractUuid,
      response: JSON.stringify(error?.response),
    });

    captureException(error);

    if (!error.response) {
      throw error;
    }

    const { errors } = error.response.data;

    if (errors) {
      Object.entries(errors).forEach(([key, value]) => {
        if (key.indexOf('formData') !== 0) {
          // Do not set store values for fields
          // that do not begin with formData prefix.
          return;
        }

        // Turns formData.contactInformation.firstName.value
        // into contactInformation.firstName.errors
        const path = key
          .replace('formData.', '')
          .replace('.value', '.errors');

        if (get(state, path, undefined) === undefined) {
          console.warn(`Trying to set field path ${path} that does not exist in the store state`);
          return;
        }

        setStoreField(state, {
          fieldPath: path,
          value,
        });
      });
    }

    if (throwOnErrors) {
      throw error;
    }
  }

  return null;
};

/**
 * Returns state to it's initial look.
 * Uses defaultValue
 */
export const resetState = (state: StateTree, clearNominees = true) => {
  if (clearNominees) {
    state.contractSettings.nominees = [];
  }

  traverseObject(state, ({ nodeKey, path, parent }) => {
    if (nodeKey === 'value' && (parent && 'defaultValue' in parent)) {
      set(state, path, parent.defaultValue);
    }
  });

  state.rehydrated.value = true;
};

export const refreshContract = async (state: StateTree, contractUuid: string) => {
  const { data } = await show(contractUuid);

  if (!data.form_data) {
    throw new Error('Missing form data');
  }

  restoreState(state, JSON.parse(data.form_data));
};

export const syncedStoreField = (store: StateTree, props: { path: string }) => {
  return {
    get () {
      return get(store, props.path);
    },

    set (value: unknown) {
      set(store, props.path, value);
    },
  };
};

export const copyAddress = (state: StateTree, { source, destination }: { source: string, destination: string }) => {
  const fieldsToCopy = [
    'street',
    'streetNumber',
    'city',
    'zip',
    'country',
    'query',
    // 'editable',
    // 'setManually'
  ];

  copyFields(state, fieldsToCopy.map((field) => ({
    pathFrom: `${source}.${field}.value`,
    pathTo: `${destination}.${field}.value`,
  })));
};
