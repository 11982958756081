<template>
  <div>
    <div
      v-show="signersVisible"
      dusk="signing-person"
    >
      <h3>{{ title }}</h3>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :debounce="0"
        :validate-on-input="true"
        field-path="signer.type"
        default-value="participant"
        @validate="validateFnc"
      >
        <LitRadio
          class="mb-20"
          dusk="signer-type"
          name="signer-type"
          :error="errors.length > 0 ? errors[0] : ''"
          :options="signerTypes"
          :model-value="value"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <h3>Kontaktní údaje {{ signerTypeTranslation }} ...</h3>
    </div>

    <div class="mt-30">
      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.firstName`"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          name="firstName"
          dusk="first-name"
          class="mb-20"
          placeholder="Např. Karel"
          :label="signerTypeLabels.firstName"
          :errors="errors"
          :warning="nameWarning"
          :model-value="value"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.lastName`"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          name="lastName"
          dusk="last-name"
          class="mb-20"
          placeholder="Např. Novotný"
          :label="signerTypeLabels.lastName"
          :errors="errors"
          :model-value="value"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.email`"
        :debounce="0"
        @validate="validateFnc"
      >
        <EmailInput
          name="email"
          dusk="email"
          :label="signerTypeLabels.email"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <div class="phone">
        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          :namespace="namespace"
          :field-path="`${storePath}.phonePrefix`"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateFnc"
        >
          <PhonePrefixSelect
            dusk="phone-prefix"
            :label="signerTypeLabels.mobile"
            :errors="errors"
            :model-value="value"
            :preselect-first="true"
            :searchable="true"
            @update:model-value="input"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          :namespace="namespace"
          :field-path="`${storePath}.phoneNumber`"
          :debounce="0"
          @validate="validateFnc"
        >
          <LitInput
            class="control__number"
            type="tel"
            name="tel"
            :model-value="value"
            :errors="errors"
            dusk="mobile"
            placeholder="Např. 777888999"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { getActivePinia } from 'pinia';
import { get, set } from 'lodash';

import LitInput from '@/js/components/Base/LitInput.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import EmailInput from '@/js/components/EmailInput.vue';
import PhonePrefixSelect from '@/js/components/PhonePrefixSelect.vue';
import type { ValidateStoreParamsFnc } from '@/js/stores/utils';
import { useCheckTitleInName } from '@/js/composables/useTitleInName.ts';

interface Props {
  namespace: string
  storePath: string
  title?: string
  validateFnc: ValidateStoreParamsFnc
  signersVisible?: boolean
  signerTypes?: { value: string, label: string }[]
}

const props = withDefaults(defineProps<Props>(), {
  title: 'Podpis na smlouvě je podpisem',
  signersVisible: true,
  signerTypes: () => [
    { value: 'participant', label: 'účastníka' },
    { value: 'legalRepresentative', label: 'zákonného zástupce' },
    { value: 'committee', label: 'opatrovníka' },
  ],
});

const activeStore = computed(() => {
  const store = getActivePinia();

  if (!store) {
    throw new Error('Pinia store is not active. Make sure you have initialized Pinia and it is active in the current context.');
  }

  const storeField = get(store.state.value, `${props.namespace}`);

  if (!storeField) {
    throw new Error(`Store field ${props.namespace} does not exist. Check the namespace prop.`);
  }

  return storeField;
});

const firstName = computed<string>(() => activeStore.value[props.storePath].firstName.value);
const nameWarning = useCheckTitleInName(firstName);

const signerType = computed({
  get () {
    return get(activeStore.value, 'signer.type.value');
  },
  set (value) {
    set(activeStore.value, 'signer.type.value', value);
  },
});

const personalDataFirstName = computed({
  get () {
    return get(activeStore.value, 'personalData.firstName.value');
  },
  set (value) {
    set(activeStore.value, 'personalData.firstName.value', value);
  },
});

const personalDataFirstNameErrors = computed({
  get () {
    return get(activeStore.value, 'personalData.firstName.errors');
  },
  set (value) {
    set(activeStore.value, 'personalData.firstName.errors', value);
  },

});

const personalDataLastName = computed({
  get () {
    return get(activeStore.value, 'personalData.lastName.value');
  },
  set (value) {
    set(activeStore.value, 'personalData.lastName.value', value);
  },

});

const personalDataLastNameErrors = computed({
  get () {
    return get(activeStore.value, 'personalData.lastName.errors');
  },
  set (value) {
    set(activeStore.value, 'personalData.lastName.errors', value);
  },

});

const signerTypeTranslation = computed(() => {
  switch (signerType.value) {
    case 'legalRepresentative':
      return 'zákonného zástupce';
    case 'committee':
      return 'opatrovníka';
    case 'curator':
      return 'poručníka';
    case 'procuracy':
      return 'zmocněnce';
    default:
      return 'účastníka';
  }
});

const signerTypeLabels = computed(() => {
  return {
    firstName: `Jméno ${signerTypeTranslation.value}`,
    lastName: `Příjmení ${signerTypeTranslation.value}`,
    email: `E-mail ${signerTypeTranslation.value}`,
    mobile: `Mobil ${signerTypeTranslation.value} (s předvolbou)`,
  };
});

function clearPersonalDataNames () {
  personalDataFirstName.value = '';
  personalDataLastName.value = '';
  personalDataFirstNameErrors.value = [];
  personalDataLastNameErrors.value = [];
}

watch(signerType, () => {
  clearPersonalDataNames();
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

.benefits {
  h2 {
    margin: 0;

    @include media(max, $md) {
      font-size: rem(24);
    }
  }
}

.phone {
  display: flex;
  max-width: 517px;

  @include media(max, $sm) {
    display: block;
  }
}
</style>
