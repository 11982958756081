<script lang="ts" setup>
import { storeToRefs } from 'pinia';

import { useRoute, useRouter } from 'vue-router';
import type { ComputedRef } from 'vue';
import { computed, ref, shallowRef, watch } from 'vue';
import ContributionSelection from '@/js/components/ContributionSelection.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import StepButtons from '@/js/views/common/StepButtons.vue';
import gtm from '@/js/services/gtm';
import { scrollToError } from '@/js/utils.ts';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import ChangeRequestFormsOfferModal from '@/js/components/Modals/ChangeRequestFormsOfferModal.vue';
import MissingChangeWarningModal from '@/js/components/Modals/MissingChangeWarningModal.vue';
import { useDateRange } from '@/js/composables/useDateRange.ts';
import ChangeRequestStepper from '@/js/components/Steppers/ChangeRequestStepper.vue';

const route = useRoute();
const router = useRouter();

const store = useChangeRequestStore();
const { validateField, validateFields } = store;
const { contractSettings } = storeToRefs(store);

const validating = ref(false);
const showFormsOfferModal = ref(false);
const displayChangeCheckModal = ref(false);

const taxOptimizationOptions = shallowRef([
  { value: true, label: 'Účastník chce sjednat Program daňové optimalizace' },
  { value: false, label: 'Účastník chce zrušit Program daňové optimalizace' },
]);

const employerActionOptions = shallowRef([
  { value: 'add', label: 'Přidat zaměstnavatele' },
  { value: 'change', label: 'Upravit zaměstnavatele' },
  { value: 'remove', label: 'Odstranit zaměstnavatele' },
]);

const taxOptimization: ComputedRef<boolean | null> = computed(() => {
  return contractSettings.value.taxOptimization.value.value;
});

const taxOptimizationChangeRequired: ComputedRef<boolean> = computed(() => {
  return contractSettings.value.taxOptimizationChangeRequired.value;
});

const contribution = computed(() => {
  return contractSettings.value.contribution.amount.value;
});

const contributionChangeRequired: ComputedRef<boolean> = computed(() => {
  return contractSettings.value.contributionChangeRequired.value;
});

const employerAction = computed(() => {
  return contractSettings.value.employer.action.value;
});

const employerChangeRequired: ComputedRef<boolean> = computed(() => {
  return contractSettings.value.employerChangeRequired.value;
});

const nothingChanged = computed(() => {
  return contractSettings.value.contributionChangeRequired.value === false
    && contractSettings.value.taxOptimizationChangeRequired.value === false
    && contractSettings.value.employerChangeRequired.value === false;
});

watch(taxOptimization, (newVal) => {
  if (newVal !== null) {
    contractSettings.value.taxOptimizationChangeRequired.value = true;
  }
});

watch(taxOptimizationChangeRequired, (newVal) => {
  if (!newVal) {
    contractSettings.value.taxOptimization.value.value = null;
  }
});

watch(contribution, (newVal) => {
  if (newVal !== null && newVal !== 0) {
    contractSettings.value.contributionChangeRequired.value = true;
  }
});

watch(contributionChangeRequired, (newVal) => {
  if (!newVal) {
    contractSettings.value.contribution.amount.value = null;
    contractSettings.value.contribution.group.value = '';
  }
});

watch(employerAction, (newVal) => {
  if (newVal !== null) {
    contractSettings.value.employerChangeRequired.value = true;
  }
});

watch(employerChangeRequired, (newVal) => {
  if (!newVal) {
    contractSettings.value.employer.action.value = null;
    contractSettings.value.employer.name.value = null;
    contractSettings.value.employer.vat.value = null;
  }
});

function setContributionEffectiveDate () {
  if (contributionChangeRequired.value) {
    contractSettings.value.contribution.effectiveDate.value = contractSettings.value.taxOptimization.effectiveDate.value;
  }
}

async function checkSubmittion () {
  if (nothingChanged.value) {
    displayChangeCheckModal.value = true;
  } else {
    await handleSubmit();
  }
}

async function handleSubmit () {
  const { contractUuid } = route.params;

  setContributionEffectiveDate();

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        'contractSettings.contribution.amount',
        'contractSettings.contribution.effectiveDate',
        'contractSettings.taxOptimization.value',
        'contractSettings.taxOptimization.effectiveDate',
        'contractSettings.employer.action',
        'contractSettings.employer.effectiveDate',
        'contractSettings.employer.vat',
        'contractSettings.employer.name',
        'contractSettings.employer.effectiveDate',
      ],
    });

    await router.push({
      name: 'changeRequest.recapitulation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('zmena-smlouvy');
  } catch (e) {
    scrollToError();

    console.warn(`There was a validation RecapitulationError: ${e}`);
  } finally {
    validating.value = false;
  }
}
</script>

<template>
  <div>
    <ChangeRequestStepper current-step="contractSettings" />

    <div class="container--sm mt-20">
      <div class="flex flex-between">
        <h3 dusk="contribution-heading" class="mt-10">
          Měsíční příspěvek
        </h3>
        <LitIconSvg
          icon-name="choice-reset"
          class="pointer"
          @click="contractSettings.contributionChangeRequired.value = false"
        />
      </div>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="contractSettings.contribution.amount"
        class="mb-40"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateField"
      >
        <ContributionSelection
          v-model:contribution-group="contractSettings.contribution.group.value"
          class="mb-30"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          :is-for-distributor="true"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <div class="flex flex-between">
        <h3>Program daňové optimalizace</h3>
        <LitIconSvg
          icon-name="choice-reset"
          class="pointer"
          @click="contractSettings.taxOptimizationChangeRequired.value = false"
        />
      </div>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="contractSettings.taxOptimization.value"
        :debounce="0"
        @validate="validateField"
      >
        <LitRadio
          class="mb-20"
          dusk="tax-optimalization"
          name="tax-optimalization"
          :options="taxOptimizationOptions"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="contractSettings.taxOptimization.effectiveDate"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateField"
      >
        <LitSelect
          class="mb-20 mt-30"
          dusk="optimalization-start-date"
          label="Datum účinnosti měsíčního příspěvku a Programu daňové optimalizace"
          :options="useDateRange(3)"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <div class="flex flex-between">
        <h3>Příspěvek zaměstnavatele</h3>
        <LitIconSvg
          icon-name="choice-reset"
          class="pointer"
          @click="contractSettings.employerChangeRequired.value = false"
        />
      </div>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="contractSettings.employer.action"
        :debounce="0"
        @validate="validateField"
      >
        <LitRadio
          class="mb-20"
          dusk="employer-change"
          name="employer-change"
          :options="employerActionOptions"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <div v-if="contractSettings.employerChangeRequired.value">
        <div v-if="contractSettings.employer.action.value !== 'remove'">
          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="changeRequest"
            field-path="contractSettings.employer.name"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              dusk="employer-name"
              name="nameOfEmployer"
              label="Název zaměstnavatele"
              placeholder="Např. AAA Auto, a.s."
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>

          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="changeRequest"
            field-path="contractSettings.employer.vat"
            :debounce="0"
            @validate="validateField"
          >
            <LitInput
              class="mb-20"
              name="employerId"
              dusk="employer-id"
              label="IČ zaměstnavatele"
              placeholder="Např. 95874375"
              :model-value="value"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            >
              <template #actions>
                <div class="actions-group">
                  <a
                    href="https://ares.gov.cz/ekonomicke-subjekty"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vyhledat IČO v obchodním rejstříku
                  </a>
                </div>
              </template>
            </LitInput>
          </ServerValidatedFormField>
        </div>

        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          namespace="changeRequest"
          field-path="contractSettings.employer.effectiveDate"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateField"
        >
          <LitSelect
            class="mb-20 mt-30"
            dusk="employer-start-date"
            label="Datum účinnosti úpravy příspěvku zaměstnavatele"
            :options="useDateRange(3)"
            :model-value="value"
            :error="errors.length > 0 ? errors[0] : ''"
            @update:model-value="input"
          />
        </ServerValidatedFormField>
      </div>

      <hr class="separator my-30">

      <div class="flex flex-between">
        <StepButtons
          namespace="changeRequest"
          previous-step="clientData"
          @submit="checkSubmittion"
        />
      </div>

      <MissingChangeWarningModal
        v-model="displayChangeCheckModal"
        @close="displayChangeCheckModal = false"
      />

      <ChangeRequestFormsOfferModal
        v-model="showFormsOfferModal"
        :forms="store.changeSettings.involvedForms.value"
        @close="showFormsOfferModal = false"
        @finish="handleSubmit"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.heading_tooltip {
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer;
}
</style>
