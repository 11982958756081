<script setup lang="ts" generic="T extends ContractSettings">
import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat.ts';

import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import type { ContractSettings } from '@/js/stores/types';

interface Props {
  storePath: T
  namespace: string
}

const props = defineProps<Props>();

function formatDate (date: string | null) {
  if (date === null) {
    return '';
  }

  return useDateTimeFormat(new Date(date), 'dd. MM. yyyy');
}
</script>

<template>
  <table class="recapitulation-table mb-16">
    <thead>
      <tr>
        <th
          colspan="2"
        >
          <h3>Nastavení smlouvy</h3>
        </th>
        <th>
          <router-link
            :to="{ name: `${props.namespace}.contractSettings` }"
            class="edit-section"
          >
            <LitIconSvg icon-name="edit_green" />

            Upravit
          </router-link>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>Místo podepsání smlouvy</td>
        <td
          colspan="2"
        >
          {{ props.storePath.contractSignaturePlace.value }}
        </td>
      </tr>
      <tr>
        <td>Datum podepsání smlouvy</td>
        <td
          colspan="2"
        >
          {{ formatDate(props.storePath.contractSignatureDate.value) }}
        </td>
      </tr>
      <tr>
        <td>Daňová optimalizace</td>
        <td
          colspan="2"
        >
          {{ props.storePath.taxOptimization.value === true ? 'Ano' : 'Ne' }}
        </td>
      </tr>

      <tr>
        <td>Příspěvek zaměstnavatele</td>
        <td
          colspan="2"
        >
          {{ props.storePath.contributionFromEmployer.value === true ? 'Ano' : 'Ne' }}
        </td>
      </tr>
      <template v-if="props.storePath.contributionFromEmployer.value">
        <tr>
          <td>Název zaměstnavatele</td>
          <td
            colspan="2"
          >
            {{ props.storePath.employerInfo.name.value }}
          </td>
        </tr>
        <tr v-show="props.storePath.employerInfo.vat.value">
          <td>IČ zaměstnavatele</td>
          <td
            colspan="2"
          >
            {{ props.storePath.employerInfo.vat.value }}
          </td>
        </tr>
      </template>
      <tr>
        <td>Určené osoby</td>
        <td
          colspan="2"
        >
          {{ props.storePath.specifyNominees.value === true ? 'Ano' : 'Ne' }}
        </td>
      </tr>
      <template v-if="props.storePath.specifyNominees.value">
        <tr
          v-for="(nominee, index) in props.storePath.nominees"
          :key="index"
        >
          <td>Určená osoba č. {{ index + 1 }}</td>
          <td
            colspan="2"
          >
            {{ nominee.firstName.value }} {{ nominee.lastName.value }}<br>
            <span v-if="nominee.usePersonalId.value !== false">
              {{ nominee.personalId.value }}
            </span>
            <span v-else>
              {{ nominee.birthdate.value }}<br>
              {{ nominee.countryOfBirth.value }}
            </span>
            <br>
            {{ nominee.permanentAddress.query.value }} <br>
            Podíl: {{ nominee.share.value }}%
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
