import axios from 'axios';
import { getContractMetadata } from '../utils';

const client = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: false,
});

const uuidPattern = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';
const idPattern = '\\d{1,10}';
const baseUri = '/api/v1/contracts';
const baseUriWithUuid = `${baseUri}/${uuidPattern}`;
const bankMeta = 'bank-metadata';

function attachAccessToken (request) {
  const match = request.url.match(uuidPattern);

  if (!match) {
    throw new Error('Uuid does not match !');
  }

  const attachToken = getContractMetadata(match[0]);

  if (attachToken === undefined) {
    throw new Error('Access token is undefined');
  }

  request.headers.authorization = `Bearer ${attachToken.accessToken.value}`;

  return request;
}

function nextRequest (request) {
  const match = request.url.match(uuidPattern);

  if (!match) {
    throw new Error('Uuid does not match !');
  }

  return request;
}

function attachAccessTokenWhileCreatingContract (request) {
  if (!('data' in request)) {
    return request;
  }

  if (request.data.contract_type === 'online') {
    const contracts = localStorage.getItem('contracts');

    if (contracts === null) {
      return request;
    }

    const localStorageContractData = JSON.parse(contracts);

    Object.keys(localStorageContractData).forEach((uuid) => {
      if (localStorageContractData[uuid].contractType === 'online') {
        const accessToken = getContractMetadata(uuid).accessToken.value;
        request.headers.authorization = `Bearer ${accessToken}`;
      }
    });
  }

  return request;
}

function attachFrontendPath (request) {
  request.headers.common['X-Frontend-Path'] = window.location.href;
}

const getRequestRules = () => [
  {
    always: true,
    action: attachFrontendPath,
  },
  {
    methods: ['get', 'patch', 'delete'],
    patterns: [
      new RegExp(baseUriWithUuid),
      new RegExp(`${baseUriWithUuid}/fields`),
      new RegExp(`${baseUriWithUuid}/files(?:/([0-9]+))?`),
      new RegExp(`${baseUriWithUuid}/${bankMeta}`),
      new RegExp(`${baseUriWithUuid}/signatures`),
      new RegExp(`${baseUri}/${idPattern}/thank-you-page`),
      new RegExp(`${baseUri}/bank-id`),
    ],
    action: attachAccessToken,
  },
  {
    methods: ['post'],
    patterns: [
      new RegExp(`${baseUri}$`),
    ],
    action: attachAccessTokenWhileCreatingContract,
  },

  {
    methods: ['post'],
    patterns: [
      new RegExp(`${baseUriWithUuid}/access-tokens`),
    ],

    action: nextRequest,
  },

  {
    methods: ['post'],
    patterns: [
      new RegExp(`${baseUriWithUuid}/files`),
      new RegExp(`${baseUriWithUuid}/redirect-tokens`),
      new RegExp(`${baseUriWithUuid}/signing-keys`),
      new RegExp(`${baseUriWithUuid}/draft-tokens`),
      new RegExp(`${baseUriWithUuid}/signatures`),
      new RegExp(`${baseUri}/bank-id`),
    ],

    action: attachAccessToken,
  },
];

client.interceptors.request.use((request) => {
  getRequestRules().forEach((rule) => {
    if (rule.always) {
      rule.action(request);
      return;
    }

    if (rule.methods.includes(request.method)) {
      rule.patterns.forEach((pattern) => {
        if (pattern.test(request.url)) {
          rule.action(request);
        }
      });
    }
  });

  return request;
});

export default client;
