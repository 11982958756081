<script setup lang="ts" generic="T extends ContactInformationReturn">
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import type { ContactInformationReturn } from '@/js/stores/createStoreFields';

interface Props {
  storePath: T
  namespace: string
  title?: string
  displayTableHead?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  displayTableHead: true,
});
</script>

<template>
  <div>
    <table
      class="recapitulation-table mb-16"
    >
      <thead>
        <tr v-if="props.displayTableHead">
          <th colspan="2">
            <h3>{{ props.title }}</h3>
          </th>
          <th>
            <router-link
              :to="{ name: `${props.namespace}.contactInformation` }"
              class="edit-section"
            >
              <LitIconSvg icon-name="edit_green" />

              Upravit
            </router-link>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>Jméno</td>
          <td
            colspan="2"
          >
            {{ props.storePath.firstName.value }}
          </td>
        </tr>
        <tr>
          <td>Příjmení</td>
          <td
            colspan="2"
          >
            {{ props.storePath.lastName.value }}
          </td>
        </tr>

        <tr>
          <td>E-mail</td>
          <td
            colspan="2"
          >
            {{ props.storePath.email.value }}
          </td>
        </tr>
        <tr>
          <td>Mobil</td>
          <td
            colspan="2"
          >
            <template
              v-if="props.storePath.phonePrefix"
            >
              +{{ props.storePath.phonePrefix.value }}
            </template>
            {{ props.storePath.phoneNumber.value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
