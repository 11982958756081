<template>
  <div dusk="photo-documents">
    <Stepper :current-step="1" />

    <div class="container--sm">
      <h2>Vyfoťte své doklady mobilním telefonem</h2>

      <div class="flex flex-center py-30">
        <img
          :src="getIconUrl('icon-PC2mobil')"
          alt=""
        >
      </div>

      <p>
        Na vaše telefonní číslo <strong>+420 {{ useFormatPhoneNumber(phoneNumber) }}</strong>
        jsme vám poslali SMS s&nbsp;odkazem na otevření stránky
        pro vyfocení dokladů.
        Klikněte na odkaz v SMS a vyfoťte příslušné doklady.
      </p>

      <p v-if="!maxAttemptsReached">
        SMS mi nedorazila,

        <button
          type="button"
          class="btn-simple"
          @click="fetchRedirectToken"
        >
          poslat znova
        </button>

        nebo

        <button
          type="button"
          class="btn-simple"
          dusk="toggle-qr-code"
          @click="isQRCodeShown = !isQRCodeShown"
        >
          <span class="mr-5">
            použít QR kód či zkopírovat odkaz zde
          </span>

          <i :class="{ 'arrow-up': isQRCodeShown || maxAttemptsReached }">
            <img
              :src="getIconUrl('arrow_down-green')"
              alt=""
            >
          </i>
        </button>
      </p>

      <p
        v-if="attemptsRemaining === 1"
        class="text-warning mb-20"
      >
        <span class="text-bold">POZOR:</span>
        Zbývá vám poslední pokus o zaslání SMS kódu.
      </p>

      <p
        v-if="maxAttemptsReached"
        class="text-danger"
      >
        Maximální počet SMS vyčerpán.
      </p>

      <p
        v-if="resendSuccessful"
        class="text-success"
      >
        Na váš telefon jsme zaslali novou SMS
        s odkazem na otevření skenování dokladů.
      </p>

      <p
        v-if="resendFailed"
        class="text-danger"
      >
        Při odesílání SMS došlo k chybě, zkuste to prosím znovu.
      </p>

      <div v-show="showQRCodeBlock">
        <div class="row">
          <div class="col-md-6 flex mb-10">
            <div class="box flex">
              <p class="pr-20">
                Naskenujte uvedený QR kód pomocí QR čtečky ve vašem telefonu
                a na zobrazené stránce vyfoťte příslušné doklady.
              </p>

              <QrcodeVue
                v-if="mobileUrl"
                :value="mobileUrl"
                level="H"
              />
            </div>
          </div>

          <div class="col-md-6 flex mb-10">
            <div class="box">
              <p>
                Zkopírujte si níže uvedený odkaz,
                otevřete ho na mobilním telefonu a vyfoťte příslušné doklady.
              </p>

              <div
                v-if="mobileUrl"
                class="link-group mt-20"
              >
                <div class="link-control">
                  <span class="link-control__wrap">
                    <a>
                      {{ mobileUrl }}
                    </a>
                  </span>
                </div>

                <button
                  dusk="mobile-url-copy"
                  class="btn btn-secondary"
                  @click="copyMobileUrl"
                >
                  Kopírovat
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h3 class="text-warning">
        Stránku nezavírejte, žádost zde můžete dokončit
      </h3>

      <LitAlert alert-type="warning">
        Jakmile dokončíte focení dokladů na mobilním telefonu,
        budete moci dokončit žádost o nové penzijní spoření buďto
        zde nebo přímo v mobilním telefonu.
      </LitAlert>
    </div>
  </div>
</template>

<script lang="ts">
import QrcodeVue from 'qrcode.vue';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { contracts, redirectTokens } from '../../../api';

import LitAlert from '../../../components/Base/LitAlert.vue';
import Stepper from '../../../components/Steppers/Stepper.vue';
import { getIconUrl } from '@/js/utils';

import { useFormatPhoneNumber } from '@/js/composables/useFormatPhoneNumber';
import { useOnlineAgreementStore } from '@/js/stores/online/agreement';
import { restoreState, updateContract } from '@/js/stores/utils';

const RESEND_STATUS = {
  RESENDING: 1,
  RESEND_SUCCESSFUL: 2,
  RESEND_FAILED: 3,
};

interface State {
  isQRCodeShown: boolean
  mobileUrl: string | null
  attemptsRemaining: number | null
  resendStatus: number | null
}

export default {
  components: {
    QrcodeVue,
    LitAlert,
    Stepper,
  },

  setup () {
    const store = useOnlineAgreementStore();
    const { contactInformation } = storeToRefs(store);

    const phoneNumber = computed(() => contactInformation.value.phoneNumber.value);

    return {
      useFormatPhoneNumber,
      getIconUrl,
      phoneNumber,
      store,
    };
  },

  data: (): State => ({
    isQRCodeShown: false,
    mobileUrl: null,
    attemptsRemaining: null,
    resendStatus: null,
  }),

  computed: {
    resending () {
      return this.resendStatus === RESEND_STATUS.RESENDING;
    },

    resendSuccessful () {
      return this.resendStatus === RESEND_STATUS.RESEND_SUCCESSFUL;
    },

    resendFailed () {
      return this.resendStatus === RESEND_STATUS.RESEND_FAILED;
    },

    maxAttemptsReached () {
      return this.attemptsRemaining === 0;
    },

    showQRCodeBlock () {
      return this.isQRCodeShown || this.attemptsRemaining === 0;
    },
  },

  async mounted () {
    await this.updateContract([{
      path: 'personalData.selectedEntryMethod.value',
      value: 'scan',
    }]);

    this.fetchRedirectToken();
    this.waitForFiles();
  },

  methods: {
    updateContract (fields: Record<string, string>[] = []) {
      const { contractUuid } = this.$route.params;

      updateContract({
        state: this.store.$state,
        contractUuid: contractUuid as string,
        fields,
      });
    },

    async resendToken () {
      this.resendSuccessful = false;

      try {
        await this.fetchRedirectToken();
        this.resendSuccessful = true;
      } catch (e) {
        this.resendSuccessful = false;
      }
    },

    async fetchRedirectToken () {
      const { contractUuid } = this.$route.params;

      try {
        const { data, headers } = await redirectTokens.create(contractUuid);

        if ('x-ratelimit-remaining' in headers) {
          this.attemptsRemaining = Number.parseInt(headers['x-ratelimit-remaining']!, 10);
        }

        this.mobileUrl = data.url;
      } catch (e: any) {
        if (e.response.status === 429) {
          console.error('Maximální poče SMS překročen');
          this.attemptsRemaining = 0;
        } else {
          throw e;
        }
      }
    },

    copyMobileUrl () {
      if (navigator.clipboard !== undefined) {
        navigator.clipboard.writeText(this.mobileUrl as string);
      } else if (window.clipboardData && window.clipboardData.setData) { // IE
        window.clipboardData.setData('Text', this.mobileUrl as string);
      }
    },

    waitForFiles () {
      if (!window.Echo) {
        console.warn('Echo is not defined (or enabled), skipping');
        return;
      }

      const { contractUuid } = this.$route.params;

      const channelName = `contracts.${contractUuid}`;

      console.warn('Listening for DocumentsScanFinish event');

      window.Echo.channel(channelName)
        .listen('DocumentsScanFinished', async (event: any) => {
          console.warn('User has finished uploading documents, verifying documents.');

          const verified = await this.verifyDocuments();

          if (verified) {
            console.warn('All documents have been provided.');

            // @see app/Http/Controllers/V1/ContractsController.php:89
            if (event.finishMethod === 'pc') {
              await this.$router.push({ name: 'onlineAgreement.personalData' });
            } else {
              // Close this page?
            }

            window.Echo.leave(channelName);
          } else {
            console.warn('Some of the documents are missing, waiting.');
          }
        });
    },

    async verifyDocuments () {
      const { contractUuid } = this.$route.params;
      const { data } = await contracts.show(contractUuid as string);

      if (!data.form_data) {
        throw new Error('Missing form data');
      }

      const formData = JSON.parse(data.form_data);

      const idCardFrontId = formData?.documents?.idCardFrontId?.value;
      const idCardBackId = formData?.documents?.idCardBackId?.value;
      const secondDocumentFrontId = formData?.documents?.secondDocumentFrontId?.value;

      if (idCardFrontId && idCardBackId && secondDocumentFrontId) {
        restoreState(this.store.$state, formData);

        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@sass/tools/variables';
    @import '@sass/tools/functions';

    .box {
      flex-grow: 1;
    }

    .link {
      &-group {
        display: flex;

        .btn {
          flex-shrink: 0;
        }
      }

      &-control {
        padding: 0 rem(11);
        display: flex;
        align-items: center;
        border-radius: 3px 0 0 3px;
        min-height: $input-height;
        border: 1px solid getColor(light-blue);
        border-right: none;
        position: relative;
        flex-basis: 330px;
        overflow: hidden;

        &__wrap {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: getColor(green);
        }
      }
    }

    .arrow-up {
      img {
        transform: rotate(180deg);
      }
    }
</style>
