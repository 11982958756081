<template>
  <div
    class="container--sm my-30 flex-column"
  >
    <h2>Vygenerované číslo smlouvy</h2>

    <div
      class="contract-number"
    >
      <h1>{{ contractNumber !== null ? contractNumber : "nepovedlo se vygenerovat číslo" }}</h1>
      <button
        class="btn btn-copy"
        @click="copyContractNumber"
      >
        {{ isCopied ? 'Zkopírováno' : 'Kopírovat' }}
      </button>
    </div>

    <h3>Nechte si číslo smlouvy poslat e-mailem</h3>

    <EmailInput
      v-model="email.value"
      :errors="email.errors"
      class="w-100 flex-shrink-1"
      name="email"
    />

    <LitButton
      v-if="contractNumberSentByEmail !== contractNumber"
      :class="[sending ? 'disabled' : '']"
      :disabled="sending"
      @click="submitStep"
    >
      POSLAT NA E-MAIL
    </LitButton>

    <div v-else>
      E-mail byl odeslán
    </div>

    <div class="line" />

    <div>
      <button
        v-if="attemptsToGenerateNumber > 0"
        class="btn btn-outline btn-generate"
        @click="generateContractNumber"
      >
        <LitIconSvg
          class="btn__icon"
          icon-name="icon-loader_green"
        />
        Vygenerovat další číslo smlouvy
      </button>

      <p><span>{{ generatedNumbersCounterText }}</span></p>
    </div>
  </div>
</template>

<script>
import { parseInt } from 'lodash/string';
import axios from 'axios';
import env from '../env';
import LitIconSvg from './Base/LitIconSvg.vue';
import EmailInput from './EmailInput.vue';
import LitButton from '@/js/components/Base/LitButton.vue';

export default {
  components: {
    LitButton,
    EmailInput,
    LitIconSvg,
  },

  props: {
    contractNumber: {
      default: null,
    },

    attemptsToGenerateNumber: {
      required: true,
      default: null,
    },

    s1: {
      required: true,
    },

    s2: {
      required: true,
    },
  },

  emits: [
    'changed',
  ],

  data: () => ({
    email: { value: null, errors: [] },
    restApiUri: env.REST_API_URI,
    sending: false,
    isCopied: false,
    contractNumberSentByEmail: null,
  }),

  computed: {
    requestData () {
      return {
        s1: this.s1,
        s2: this.s2,
        validateOnly: ['s1', 's2'],
      };
    },

    generatedNumbersCounterText () {
      switch (true) {
        case this.attemptsToGenerateNumber >= 5:
          return `Pro dnešní den vám zbývá ${this.attemptsToGenerateNumber} čísel smluv`;
        case this.attemptsToGenerateNumber === 1:
          return `Pro dnešní den vám zbývá ${this.attemptsToGenerateNumber} číslo smlouvy`;
        case this.attemptsToGenerateNumber > 1 && this.attemptsToGenerateNumber < 5:
          return `Pro dnešní den vám zbývají ${this.attemptsToGenerateNumber} čísla smlouvy`;
        default:
          return 'Limit pro generování čísel smluv vyčerpán';
      }
    },
  },

  methods: {
    copyContractNumber () {
      if (this.contractNumber === null) {
        // eslint-disable-next-line no-alert
        alert('číslo smlouvy je prázdné');
        throw new Error('Není možné zkopírovat, číslo smlouvy je prázdné');
      }

      try {
        navigator.clipboard.writeText(this.contractNumber);
        this.isCopied = true;
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert('nepodařilo se zkopírovat');
      }
    },

    async generateContractNumber () {
      try {
        const { data, headers } = await axios.post(`${this.restApiUri}/v1/generated-numbers`, this.requestData);
        this.$emit('changed', { contractNumber: data.data.generated_number, remainingLimit: parseInt(headers['x-remaining-generation']) });
        this.isCopied = false;
      } catch (e) {
        if (e.response?.status === 422) {
          console.warn(e.response.data);
        }
      }
    },

    async submitStep () {
      try {
        this.clearErrors();
        this.sending = true;
        const { data } = await axios.put(`${this.restApiUri}/v1/generated-numbers`, {
          ...this.requestData,
          contractNumber: this.contractNumber,
          sendBy: 'email',
          email: this.email.value,
          validateOnly: ['contractNumber', 'email'],
        });

        this.contractNumberSentByEmail = data.data.generated_number;
      } catch (e) {
        if (e.response?.status === 422) {
          const { errors } = e.response.data;

          Object.entries(errors).forEach(([key, value]) => {
            this.$data[key].errors.push(...value);
          });
        }
      } finally {
        this.sending = false;
      }
    },

    clearErrors () {
      this.email.errors = [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/functions';

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contract-number {
  background: var(--color-lighter-blue);
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .btn-copy {
    border: 2.2px solid var(--color-default);
    padding: 15px 60px;
    border-radius: 40px;

    &:hover {
      background: var(--color-default);
      color: var(--color-lighter-blue);
    }

    &:active {
      background: var(--color-lighter-blue);
      color: var(--color-default);
    }
  }

  .disabled {
    background: var(--color-disabled);
  }

  h1 {
    font-size: 3rem;
    font-weight: bold;
  }
}

.line {
  border-bottom: 1px solid getColor(light-grey);
  text-align: center;
  width: 100%;
  margin: 10px 0;
}

.btn-generate {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  margin: 30px 0;
}

.flex-shrink-1 {
  label {
    flex-shrink: 1;
  }
}
</style>
